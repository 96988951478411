import { CSVLink } from 'react-csv'
import styled from 'styled-components'
import { Button } from '@mui/material'
import GetAppIcon from '@mui/icons-material/GetApp'
import { useTranslation } from 'react-i18next'

const TableCSVLink = ({
  csvHeaders,
  data,
  fileName = 'table-data-export.csv',
  filterDownloadCsvValue,
  filterDownloadCsvKeys,
}: {
  csvHeaders?: { label: string; key: string }[] | string[]
  data: any[]
  fileName?: string
  filterDownloadCsvValue?: string
  filterDownloadCsvKeys?: string
}) => {
  const { t } = useTranslation('table_component')
  const csvFilteredData =
    filterDownloadCsvValue && filterDownloadCsvKeys
      ? data.filter(
          (row) => row.values[filterDownloadCsvKeys] !== filterDownloadCsvValue
        )
      : data

  return (
    <CSVLinkStyled
      separator={';'}
      data={csvFilteredData.map((row) => row.values)}
      filename={fileName}
      headers={csvHeaders ? csvHeaders : undefined}
    >
      <ButtonCSV
        color="primary"
        variant="outlined"
        size="large"
        startIcon={<GetAppIcon />}
        disabled={!data.length}
      >
        {t('download_csv')}
      </ButtonCSV>
    </CSVLinkStyled>
  )
}

export default TableCSVLink

const CSVLinkStyled = styled(CSVLink)`
  text-decoration: none;
`
const ButtonCSV = styled(Button)`
  border-radius: 100px;
`
