import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Grid } from '@mui/material'
import { themeColors } from '../../const/colors'
import { TextBody1Bold, Title, TextBody1Medium } from '../Styles/TextCustom'
import { IOrigoAccount } from '../../graphql/hid/getOrigoAccount'

interface IProps {
  account: IOrigoAccount
}

const EditHIDCardSettings = ({ account }: IProps) => {
  const { t } = useTranslation(['hid'])

  return (
    <PaperSection>
      <Row>
        <Text>{t('parking_access')}: </Text>
        <TextBold>{account.withParkingAccess ? 'Tak' : 'Nie'}</TextBold>
      </Row>
    </PaperSection>
  )
}

export default EditHIDCardSettings

const Text = styled(TextBody1Medium)`
  display: inline;
`
const TextBold = styled(TextBody1Bold)`
  display: inline;
`
const Row = styled(Grid)`
  padding: 2px 0;
`
const PaperSection = styled.div`
  background-color: ${themeColors.white};
  border-radius: 16px;
  padding: 20px 16px;
  margin: 16px 0;
`
