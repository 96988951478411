import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import {
  IGetParkingPoolProfileVars,
  IGetParkingPoolProfileData,
  GET_PARKING_POOL_PROFILE,
} from '../../graphql/parkingPool/getParkingPoolProfile'
import { ApolloError, useQuery } from '@apollo/client'
import BaseSelect from '../Shared/BaseSelect'
import { CircularProgress } from '@mui/material'
import Error from '../Shared/Error'

interface IProfileOption {
  value: string
  label: string
}

const ParkingPoolProfileSelect = ({
  value,
  setValue,
  parkingPoolId,
}: {
  value?: IProfileOption
  setValue: (v: IProfileOption) => void
  parkingPoolId: string
}) => {
  const { t } = useTranslation(['parking_pool'])
  const [errorMessage, setErrorMessage] = useState('')
  const [profiles, setProfiles] = useState<IProfileOption[]>([])
  const noProfile = { value: 'no_profile', label: t('no_profile') }

  const onError = (err: ApolloError) => setErrorMessage(err.message)

  const { loading } = useQuery<
    IGetParkingPoolProfileData,
    IGetParkingPoolProfileVars
  >(GET_PARKING_POOL_PROFILE, {
    fetchPolicy: 'no-cache',
    onCompleted: (data: IGetParkingPoolProfileData) => {
      const items =
        data?.getParkingPool.profiles.map(({ id, name }) => ({
          value: id,
          label: name,
        })) || []

      setProfiles([...items, noProfile])
    },
    onError,
    variables: {
      id: parkingPoolId,
    },
  })

  const handleSelectChange = (option: any) => {
    setValue(option as IProfileOption)
  }

  const defaultValue = value || noProfile

  if (loading) {
    return <CircularProgress />
  }

  if (!profiles.length) {
    return null
  }

  return (
    <InputContainer>
      <BaseSelect
        placeholder={t('choose_profile')}
        name="profile"
        options={profiles}
        value={defaultValue}
        onChange={handleSelectChange}
        isLoading={!profiles.length}
      />
      {errorMessage && <Error errorMessage={errorMessage} />}
    </InputContainer>
  )
}

export default ParkingPoolProfileSelect

const InputContainer = styled.div`
  margin: 16px 0;
`
