import React, { FC } from 'react'
import styled from 'styled-components'
import { List } from '@mui/material'
import { navList } from '../../const/menuItems'
import { checkRole } from '../../utils/checkRole'
import { useAppSelector } from '../../redux/store'
import NavItem from './NavItem'
import MySolvyRedirect from './MySolvyRedirect'

interface INavListProps {
  isMenuOpen?: boolean
}

const NavList: FC<INavListProps> = ({ isMenuOpen }) => {
  const { roles } = useAppSelector(state => state.user)

  return (
    <ListContainer>
      <List>
        <List component="div">
          {navList
            .filter(item => item.roles && checkRole(roles, item.roles))
            .map((item, i) => (
              <NavItem item={item} key={i} isMenuOpen={isMenuOpen} />
            ))}
          <MySolvyRedirect />
        </List>
      </List>
    </ListContainer>
  )
}

const ListContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
`

export default NavList
