import React, { useEffect } from 'react'
import styled from 'styled-components'
import {
  useTable,
  useGlobalFilter,
  TableOptions,
  useSortBy,
  useRowSelect,
  Hooks,
  Cell,
  usePagination,
  useFilters,
} from 'react-table'
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableFooter,
  TableSortLabel,
  Checkbox,
  TableContainer,
  TablePagination,
  Grid,
} from '@mui/material'
import GlobalFilterInput from './Shared/GlobalFilterInput'
import TableCSVLink from '../components/Table/TableCSVLink'
interface IRTTableProps<T extends object> extends TableOptions<T> {
  rowSelect?: boolean
  onSelectionChange?: (data: T[]) => void
  pagination?: boolean
  onRowClick?: (rowData: T) => void
  actionsComponent?: (rowData: T) => React.ReactNode
  getCellProps?: (cell: Cell<T, any>) => object
  getRowProps?: (rowData: T) => object
  gFilter?: boolean
  csvExport?: boolean
  orderByProps?: string
  sortDesc?: boolean
  csvHeaders?: any
  filterDownloadCsvValue?: string
  filterDownloadCsvKeys?: string
  loginFilterQuery?: any
  isFiltersOff?: boolean
  csvFileName?: string
  children?: React.ReactNode
}

const defaultPropGetter = (cell: any) => ({})
const defaultRowPropGetter = (row: any) => ({})

const RTTable = <T extends object>({
  columns,
  data,
  rowSelect,
  onSelectionChange,
  pagination,
  onRowClick,
  actionsComponent,
  getCellProps = defaultPropGetter,
  getRowProps = defaultRowPropGetter,
  gFilter = true,
  hiddenColumns = [],
  csvExport,
  orderByProps = '',
  sortDesc = true,
  csvHeaders,
  filterDownloadCsvValue,
  filterDownloadCsvKeys,
  loginFilterQuery,
  isFiltersOff = false,
  csvFileName = 'table-data-export.csv',
  children,
}: IRTTableProps<T>) => {
  const selectionColumnId = 'selection'
  const actionColumnId = 'action'
  const getCellPadding = (columnId: string) =>
    columnId === selectionColumnId
      ? 'checkbox'
      : columnId === actionColumnId
      ? 'none'
      : undefined

  const customHooks = (hooks: Hooks<T>) => {
    rowSelect &&
      hooks.visibleColumns.push(columns => [
        {
          id: selectionColumnId,
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <Checkbox {...getToggleAllRowsSelectedProps()} />
          ),
          Cell: ({ row }: any) => (
            <Checkbox
              {...row.getToggleRowSelectedProps()}
              onClick={e => e.stopPropagation()}
            />
          ),
        },
        ...columns,
      ])
    actionsComponent &&
      hooks.visibleColumns.push((columns: any) => [
        ...columns,
        {
          id: actionColumnId,
          Cell: ({ row }: any) => actionsComponent(row.original),
        },
      ])
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    prepareRow,
    rows,
    page,
    gotoPage,
    setPageSize,
    selectedFlatRows,
    state: { pageIndex, pageSize, globalFilter },
    setGlobalFilter,
    setHiddenColumns,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        hiddenColumns,
        pageSize: 20,
        sortBy: [{ id: orderByProps, desc: sortDesc }],
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination,
    useRowSelect,
    customHooks,
  )

  useEffect(() => {
    setHiddenColumns(
      columns
        .filter(column => column.isVisible === false)
        .map(column => column.accessor as string),
    )
    onSelectionChange &&
      onSelectionChange(selectedFlatRows.map(d => d.original))
  }, [selectedFlatRows, onSelectionChange, columns, setHiddenColumns])

  return (
    <div>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          {gFilter && (
            <GlobalFilterInput
              filter={globalFilter}
              setFilter={setGlobalFilter}
            />
          )}
        </Grid>
        <Grid item>
          {csvExport && (
            <TableCSVLink
              csvHeaders={csvHeaders}
              fileName={csvFileName}
              data={selectedFlatRows}
              filterDownloadCsvValue={filterDownloadCsvValue}
              filterDownloadCsvKeys={filterDownloadCsvKeys}
            />
          )}
        </Grid>
      </Grid>
      {children}
      <TableContainer>
        <TableStyled {...getTableProps()}>
          <TableHead>
            {headerGroups.map(headerGroup => (
              <TableRow
                {...headerGroup.getHeaderGroupProps()}
                key={headerGroup.id}
              >
                {headerGroup.headers.map(column => {
                  return (
                    <TableCell
                      {...column.getHeaderProps()}
                      padding={getCellPadding(column.id)}
                      key={column.id}
                    >
                      <TableSortLabel
                        active={column.isSorted}
                        direction={column.isSortedDesc ? 'desc' : 'asc'}
                        onClick={() => {
                          column.id !== 'selection' &&
                            (column.isSortedDesc
                              ? column.toggleSortBy(false, false)
                              : column.toggleSortBy(true, false))
                        }}
                      >
                        <>{column.render('Header')}</>
                      </TableSortLabel>
                    </TableCell>
                  )
                })}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {(pagination ? page : rows).map(row => {
              prepareRow(row)
              return (
                <TableRowStyled
                  {...row.getRowProps([getRowProps(row.original)])}
                  key={row.id}
                  hover
                  selected={row.isSelected}
                  isClickable={!!onRowClick}
                  onClick={() => onRowClick && onRowClick(row.original)}
                >
                  {row.cells.map(cell => {
                    return (
                      <TableCell
                        {...cell.getCellProps([getCellProps(cell)])}
                        padding={getCellPadding(cell.column.id)}
                        key={cell.column.id}
                      >
                        <>{cell.render('Cell')}</>
                      </TableCell>
                    )
                  })}
                </TableRowStyled>
              )
            })}
          </TableBody>
          {/* <TableFooter>
            {footerGroups.map(group => (
              <TableRow {...group.getFooterGroupProps()}>
                {group.headers.map(column => (
                  <CellStyled {...column.getFooterProps()} key={column.id}>
                    <>{column.render('Footer')}</>
                  </CellStyled>
                ))}
              </TableRow>
            ))}
          </TableFooter> */}
        </TableStyled>
      </TableContainer>

      {pagination && (
        <TablePagination
          component="div"
          count={rows.length}
          page={pageIndex}
          onPageChange={(e, page) => gotoPage(page)}
          rowsPerPageOptions={[10, 20, 30]}
          rowsPerPage={pageSize}
          onRowsPerPageChange={e => setPageSize(Number(e.target.value))}
        />
      )}
    </div>
  )
}

export default RTTable

const TableRowStyled = styled(({ isClickable, ...props }) => (
  <TableRow {...props} />
))<{ isClickable?: boolean }>`
  ${({ isClickable }) => isClickable && 'cursor: pointer;'}
`
const TableStyled = styled(Table)`
  overflow-x: scroll;
`
const CellStyled = styled(TableCell)`
  font-size: 16px;
  font-weight: 600;
`
