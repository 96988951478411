import { useState } from 'react'
import { BaseButton } from '../Shared/BaseButton'
import { useTranslation } from 'react-i18next'
import { ApolloError, useMutation } from '@apollo/client'
import {
  ICreateOrigoAccountData,
  ICreateOrigoAccountVars,
  CREATE_ORIGO_ACCOUNT,
} from '../../graphql/hid/createOrigoAccount'
import styled from 'styled-components'
import { toast } from 'react-toastify'
import { IUserDataRow } from './HIDUsersTable'
import { Grid } from '@mui/material'
import Error from '../Shared/Error'
import { CircularProgress } from '@mui/material'
import { themeColors } from '../../const/colors'
import { TextBody1Bold, Title } from '../Styles/TextCustom'
import CardSettingsForm from './CardSettingsForm'
import { useForm } from 'react-hook-form'
import { getBase64 } from '../../utils/getBase64'

interface IProps {
  userData: IUserDataRow
  refetch: () => void
}

type FormData = {
  withParkingAccess: boolean
  carPlate: string
  comment: string
  attachment: string
}

const HIDCreateAccount = ({ userData, refetch }: IProps) => {
  const { t } = useTranslation(['hid'])
  const [errorMessage, setErrorMessage] = useState('')

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<FormData>()

  const successNotify = (message: string) => toast.dark(message)
  const { login } = userData

  const onCompleted = (data: ICreateOrigoAccountData) => {
    const { status, error } = data.CreateMobileUserAccount
    if (status) {
      successNotify(t('success_message'))
      refetch()
    } else {
      error && setErrorMessage(error)
    }
  }

  const [createHIDAccessAccount, { loading }] = useMutation<
    ICreateOrigoAccountData,
    ICreateOrigoAccountVars
  >(CREATE_ORIGO_ACCOUNT, {
    onCompleted,
    onError: (error: ApolloError) => setErrorMessage(error.message),
  })

  const handleCreateAccount = async (data: FormData) => {
    const { withParkingAccess, carPlate, comment, attachment } = data
    let attachmentBase64: any
    if (attachment) {
      await getBase64(attachment).then(res => {
        attachmentBase64 = res || ''
      })
    }

    await createHIDAccessAccount({
      variables: {
        login,
        withParkingAccess,
        carPlate,
        comment,
        attachment: String(attachmentBase64 || ''),
      },
    })
  }

  if (loading) {
    return (
      <LoaderWrapper>
        <TextBody1Bold color={themeColors.primary}>
          {t('account_under_creation')}
        </TextBody1Bold>
        <CustomProgress size={50} />
      </LoaderWrapper>
    )
  }

  return (
    <>
      <PaperSection>
        <TitleStyled>{`${t('card_settings')}:`}</TitleStyled>
        <CardSettingsForm control={control} errors={errors} watch={watch} />
      </PaperSection>
      <ButtonWrapper>
        <BaseButton onClick={handleSubmit(handleCreateAccount)}>
          {t('create_card')}
        </BaseButton>
      </ButtonWrapper>

      {errorMessage && <Error errorMessage={errorMessage} />}
    </>
  )
}

export default HIDCreateAccount

const ButtonWrapper = styled(Grid)`
  display: flex;
  justify-content: center;
  padding: 16px 0;
`
const CustomProgress = styled(CircularProgress)`
  color: ${themeColors.primary};
  margin: 16px;
`
const LoaderWrapper = styled.div`
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
`
const TitleStyled = styled(Title)`
  display: block;
  margin: 0 0 16px 0;
`
const PaperSection = styled.div`
  background-color: ${themeColors.white};
  border-radius: 16px;
  padding: 20px 16px;
  margin: 16px 0;
`
