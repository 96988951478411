import Input from '../Shared/Input'
import { BaseButton } from '../Shared/BaseButton'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ApolloError, useMutation } from '@apollo/client'
import {
  UPDATE_PARKING_POOL_USER_DATA,
  IUpdateParkingPoolUserDataData,
  IUpdateParkingPoolUserDataVars,
} from '../../graphql/parkingPool/updateParkingPoolUserData'
import { toast } from 'react-toastify'
import FullScreenLoader from '../Shared/FullScreenLoader'
import { Title } from '../Styles/TextCustom'
import styled from 'styled-components'
import { CAR_PLATE_REGEX } from '../../const/regex'
import { themeColors } from '../../const/colors'

type FormData = {
  enabled: boolean
  carPlate: string
  carPlate2: string
}

interface IProps {
  parkingPoolData: {
    id: string
    carPlate?: string
    carPlate2?: string
    enabled?: boolean
  }
  refetch: () => void
  listRefetch: () => void
}

const EditParkingPoolCarPlates = ({
  parkingPoolData,
  refetch,
  listRefetch,
}: IProps) => {
  const { carPlate, carPlate2, id } = parkingPoolData
  const { t } = useTranslation(['user_details', 'tenant'])
  const successNotify = (message: string) => toast.dark(message)

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<FormData>()

  const updateCompleted = async (data: IUpdateParkingPoolUserDataData) => {
    refetch()
    listRefetch()
    successNotify(`${t('generic:success_message')}`)
  }

  const [updateParkingPoolUser, { loading }] = useMutation<
    IUpdateParkingPoolUserDataData,
    IUpdateParkingPoolUserDataVars
  >(UPDATE_PARKING_POOL_USER_DATA, {
    onCompleted: updateCompleted,
    onError: (err: ApolloError) => alert(err.message),
  })

  const updateSharedParking = async (data: FormData) => {
    await updateParkingPoolUser({
      variables: {
        id,
        carPlate: data.carPlate.trim().toUpperCase(),
        carPlate2: data.carPlate2.trim().toUpperCase(),
      },
    })
  }

  if (loading) {
    return <FullScreenLoader />
  }

  return (
    <>
      <PaperSection>
        <TitleStyled>{t('parking_pool:edit_car_plates')}</TitleStyled>
        <Input
          label={t('car_plate')}
          name={'carPlate'}
          control={control}
          errors={errors}
          rules={{
            required: `${t('form:field_required')}`,
            pattern: {
              value: CAR_PLATE_REGEX,
              message: t('form:valid_car_plate'),
            },
          }}
          defaultValue={carPlate || ''}
        />
        <Input
          label={t('car_plate')}
          name={'carPlate2'}
          control={control}
          errors={errors}
          rules={{
            pattern: {
              value: CAR_PLATE_REGEX,
              message: t('form:valid_car_plate'),
            },
          }}
          defaultValue={carPlate2 || ''}
        />
        <BaseButton onClick={handleSubmit(updateSharedParking)}>
          {t('btn:save')}
        </BaseButton>
      </PaperSection>
    </>
  )
}

export default EditParkingPoolCarPlates

const PaperSection = styled.div`
  background-color: ${themeColors.white};
  border-radius: 16px;
  padding: 20px 16px;
  margin: 16px 0;
`
const TitleStyled = styled(Title)`
  margin-bottom: 16px;
  display: block;
`
