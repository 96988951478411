import { gql } from '@apollo/client'
import { Roles } from '../../const/permissions'

export interface IGetMyRolesData {
  getMyRoles: {
    status: boolean
    errors: {
      message: string
      code: number
    }[]
    roles: {
      name: Roles
    }[]
  }
}

export const GET_MY_ROLES = gql`
  query GetMyRoles {
    getMyRoles {
      errors {
        message
        code
      }
      roles {
        name
      }
    }
  }
`
