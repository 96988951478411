import { IUserState } from './types/IUserState'
import { IUserActions } from './types/IUserActions'
import { UserActionTypes } from './types/UserActionTypes'

const initialState: IUserState = {
  firstName: '',
  lastName: '',
  fullName: '',
  login: '',
  language: '',
  signedIn: false,
  tenant: false,
  tenantName: '',
  roles: [],
  accessToken: '',
  refreshToken: '',
  isAdmin: false,
  email: undefined,
  workEmail: undefined,
  userDetailsLogin: null,
}

const reducer = (state = initialState, action: IUserActions) => {
  switch (action.type) {
    case UserActionTypes.SET_SIGNED_IN:
      return {
        ...state,
        signedIn: action.signedIn,
      }
    case UserActionTypes.SET_ADMIN:
      return {
        ...state,
        isAdmin: action.isAdmin,
      }
    case UserActionTypes.SET_USER_ROLES:
      return {
        ...state,
        isAdmin: action.isAdmin,
        roles: action.roles,
      }
    case UserActionTypes.SET_USER_DATA:
      const fullName = `${action.data.firstName} ${action.data.lastName}`
      return { ...state, ...action.data, fullName }
    case UserActionTypes.SET_TOKENS:
      const { accessToken, refreshToken } = action.data
      return {
        ...state,
        accessToken,
        refreshToken,
      }
    case UserActionTypes.LOGOUT:
      localStorage.removeItem('refreshToken')
      localStorage.removeItem('accessToken')
      return initialState
    case UserActionTypes.SET_USERS_DETAILS:
      return {
        ...state,
        userDetailsLogin: action.userDetailsLogin,
      }
    default:
      return state
  }
}

export default reducer
