import { gql } from '@apollo/client'

export interface IAssignUserToParkingPoolData {
  updateParkingPool: {
    parkingPool: {
      users: {
        login: string
      }[]
    }[]
    numUids: number[]
  }
}

export interface IAssignUserTParkingPoolVars {
  id: string
  login: string
}

export const ASSIGN_USER_TO_PARKING_POOL = gql`
  mutation updateParkingPool($id: ID!, $login: String) {
    updateParkingPool(
      input: { filter: { id: [$id] }, set: { users: { login: $login } } }
    ) {
      numUids
      parkingPool @cascade(fields: "users") {
        users(filter: { login: { eq: $login } }) {
          login
        }
      }
    }
  }
`
