import { gql } from '@apollo/client'

export interface ICheckUserParkingPoolAssignmentVars {
  tenant: string
  login: string
}

export interface ICheckUserParkingPoolAssignmentData {
  getTenant: {
    parkingPool: {
      id: string
      users: {
        login: string
      }[]
    }
  }
}

export const CHECK_USER_PARKING_POOL_ASSIGNMENT = gql`
  query getTenant($tenant: String, $login: String) {
    getTenant(name: $tenant) {
      parkingPool: parking_pool {
        id
        users(filter: { login: { eq: $login } }) {
          login
        }
      }
    }
  }
`
