import { gql } from '@apollo/client'

export interface IParkingPool {
  id: string
  showPlaceNames: boolean
  profiles: {
    id: string
    name: string
  }[]
  users: {
    login: string
  }[]
}

export interface IGetTenantParkingPoolUsersVars {
  tenant: string
}

export interface IGetTenantParkingPoolUsersData {
  getTenant: {
    parkingPool: IParkingPool
  }
}

export const GET_TENANT_PARKING_POOL_USERS = gql`
  query getTenant($tenant: String) {
    getTenant(name: $tenant) {
      parkingPool: parking_pool {
        id
        showPlaceNames: show_place_names
        profiles(filter: { disabled: false }) {
          id
          name
          disabled
        }
        users {
          login
        }
      }
    }
  }
`
