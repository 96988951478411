import { useMemo, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import {
  IParkingPoolUser,
  AccessCardType,
} from '../../graphql/users/queryUsersList'
import RTTable from '../../components/RTTable'
import { ID } from '../../graphql/IQueryData'
import { getTextColor } from '../../utils/getColor'
import { IParkingPool } from '../../graphql/parkingPool/getTenantParkingPoolUsers'

interface ITableColumns {
  Header: string
  accessor:
    | 'fullName'
    | 'login'
    | 'workEmail'
    | 'tenantName'
    | 'assigned'
    | 'cardType'
    | 'cardNumber'
    | 'carPlate'
    | 'carPlate2'
    | 'profile'
}

export interface IUserDataRow {
  fullName: string
  firstName: string
  lastName: string
  login: string
  workEmail?: string
  tenantName: string
  tenantId: string
  accessCard?: {
    number: string
    type: AccessCardType
  }
  seos?: {
    disabled?: boolean
    id: ID
    prefix: string
  }
  parkingPoolData: {
    id: string
    carPlate?: string
    carPlate2?: string
    enabled?: boolean
  }
  assigned: string
  cardType?: AccessCardType
  cardNumber?: string
  carPlate?: string
  carPlate2?: string
  profile?: string
}

const ParkingPoolUsersTable = ({
  children,
  users,
  assigned,
  setOpenUserDetails,
  parkingPool,
}: {
  children: ReactNode
  users: IParkingPoolUser[]
  assigned: { login: string }[]
  setOpenUserDetails: (v: IUserDataRow | undefined) => void
  parkingPool: IParkingPool
}) => {
  const { t } = useTranslation(['columns', 'user_details', 'admin_menu'])
  const { profiles } = parkingPool

  const tableColumns: ITableColumns[] = useMemo(
    () => [
      {
        Header: t('full_name'),
        accessor: 'fullName',
      },
      {
        Header: t('login'),
        accessor: 'login',
      },
      {
        Header: t('work_email'),
        accessor: 'workEmail',
      },
      {
        Header: t('company'),
        accessor: 'tenantName',
      },
      {
        Header: t('card_type'),
        accessor: 'cardType',
        isVisible: false,
      },
      {
        Header: t('card_number'),
        accessor: 'cardNumber',
        isVisible: false,
      },
      {
        Header: t('car_plate'),
        accessor: 'carPlate',
        isVisible: false,
      },
      {
        Header: t('car_plate2'),
        accessor: 'carPlate2',
        isVisible: false,
      },
      {
        Header: t('user_assigned'),
        accessor: 'assigned',
      },
      {
        Header: t('profile'),
        accessor: 'profile',
        isVisible: !!profiles.length,
      },
    ],
    [t],
  )

  const tableData: IUserDataRow[] = useMemo(
    () =>
      users.map(
        ({
          login,
          firstName,
          lastName,
          email,
          workEmail,
          tenant,
          accessCard,
          parkingPoolData,
          parkingPoolProfile,
        }) => ({
          login,
          firstName,
          lastName,
          fullName: `${firstName} ${lastName}`,
          workEmail: workEmail?.email ? workEmail.email : email?.email,
          tenantName: tenant.name || '',
          tenantId: tenant.id || '',
          accessCard,
          seos: tenant.seos,
          parkingPoolData,
          assigned: !!assigned.find(item => item.login === login)
            ? t('bool:yes')
            : t('bool:no'),
          cardType: accessCard?.type,
          cardNumber: accessCard?.number,
          carPlate: parkingPoolData?.carPlate,
          carPlate2: parkingPoolData?.carPlate2,
          profile: parkingPoolProfile?.name || '-',
        }),
      ) || [],
    [users, assigned, t],
  )

  return (
    <RTTable
      columns={tableColumns}
      data={tableData}
      onRowClick={rowData => setOpenUserDetails(rowData)}
      pagination
      orderByProps="fullName"
      sortDesc={false}
      rowSelect
      csvExport
      getCellProps={({ column, row }) =>
        column.id === 'assigned'
          ? {
              style: {
                color: getTextColor(row.original.assigned),
                fontWeight: 600,
              },
            }
          : {}
      }
    >
      {children}
    </RTTable>
  )
}

export default ParkingPoolUsersTable
