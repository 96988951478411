import React from 'react'
import { alertTimestamp } from '../../utils/alertTimestamp'
import { TextError, TextCaption } from '../Styles/TextCustom'
import { themeColors } from '../../const/colors'
import styled from 'styled-components'

const Error = ({ errorMessage }: { errorMessage: string }) => {
  return (
    <Container>
      <Content>{errorMessage}</Content>
      <Date color={themeColors.error}>{`${alertTimestamp()}`}</Date>
    </Container>
  )
}

export default Error

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
const Date = styled(TextCaption)`
  margin: 0;
`
const Content = styled(TextError)`
  margin-bottom: 0;
`
