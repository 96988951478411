import { gql } from '@apollo/client'

export enum OrigoEventName {
  NEW = 'NEW',
  USER_CREATED = 'USER_CREATED',
  USER_DELETE_INITIATED = 'USER_DELETE_INITIATED',
  USER_DELETED = 'USER_DELETED',
  USER_UPDATED = 'USER_UPDATED',
  CREDENTIAL_UNBOUND = 'CREDENTIAL_UNBOUND',
  CREDENTIAL_ISSUE_INITIATED = 'CREDENTIAL_ISSUE_INITIATED',
  CREDENTIAL_ISSUING = 'CREDENTIAL_ISSUING',
  CREDENTIAL_ISSUED = 'CREDENTIAL_ISSUED',
  CREDENTIAL_REVOKING = 'CREDENTIAL_REVOKING',
  CREDENTIAL_REVOKED = 'CREDENTIAL_REVOKED',
  INVITATION_PENDING = 'INVITATION_PENDING',
  CREDENTIAL_RESERVED = 'CREDENTIAL_RESERVED',
  CREDENTIAL_CREATING = 'CREDENTIAL_CREATING',
  CREDENTIAL_CREATION_FAILURE = 'CREDENTIAL_CREATION_FAILURE',
  INVITATION_ACKNOWLEDGED = 'INVITATION_ACKNOWLEDGED',
  INVITATION_EXPIRED = 'INVITATION_EXPIRED',
  INVITATION_CANCELLED = 'INVITATION_CANCELLED',
  INVITATION_CANCEL_INITIATED = 'INVITATION_CANCEL_INITIATED',
  ENDPOINT_ACTIVE = 'ENDPOINT_ACTIVE',
  ENDPOINT_TERMINATED = 'ENDPOINT_TERMINATED',
  ENDPOINT_DELETE_INITIATED = 'ENDPOINT_DELETE_INITIATED',
  CREDENTIALCONTAINER_PERSONALIZED = 'CREDENTIALCONTAINER_PERSONALIZED',
  PASS_CREATED = 'PASS_CREATED',
  PASS_ISSUE_INITIATED = 'PASS_ISSUE_INITIATED',
  PASS_ISSUING = 'PASS_ISSUING',
  PASS_ISSUE_FAILED = 'PASS_ISSUE_FAILED',
  PASS_CANCELLED = 'PASS_CANCELLED',
  PASS_ACTIVE = 'PASS_ACTIVE',
  PASS_SUSPENDING = 'PASS_SUSPENDING',
  PASS_SUSPENDED = 'PASS_SUSPENDED',
  PASS_RESUMING = 'PASS_RESUMING',
  PASS_USER_SUSPENDING = 'PASS_USER_SUSPENDING',
  PASS_USER_SUSPENDED = 'PASS_USER_SUSPENDED',
  PASS_USER_RESUMING = 'PASS_USER_RESUMING',
  PASS_REVOKING = 'PASS_REVOKING',
  PASS_REVOKED = 'PASS_REVOKED',
  PASS_REVOKE_FAILED = 'PASS_REVOKE_FAILED',
  PASS_USER_REVOKING = 'PASS_USER_REVOKING',
  PASS_USER_REVOKED = 'PASS_USER_REVOKED',
  PASS_UPDATED = 'PASS_UPDATED',
}

export interface IOrigoCard {
  id: string
  status: OrigoEventName
  current: boolean
  cardNumber: number
  accessGranted: boolean
  privilegeRequested: boolean
  privilegeGranted: boolean
  invitationCode: string
  deviceId: string
  user: {
    login: string
  }
  origoEndpoint: {
    current: boolean
    model: string
    os: string
  }
  mysolvyTicketStatus: String
  mysolvyTicketNr: String
  mysolvyTicketId: String
}

export interface IOrigoAccount {
  id: string
  userId: string
  status: OrigoEventName
  withParkingAccess: boolean
  withCarPlate: string
  origoCards: IOrigoCard[]
}

export interface IGetOrigoAccountVars {
  login: string
}

export interface IGetOrigoAccountData {
  getUser: {
    tenant: {
      name: string
    }
    origoAccounts: IOrigoAccount[]
  }
}

export const GET_ORIGO_ACCOUNT = gql`
  query GetOrigoAccount($login: String!) {
    getUser(login: $login) {
      tenant {
        name
      }
      origoAccounts: origo_accounts(
        filter: { current: true }
        first: 1
        order: { desc: createdAt }
      ) {
        id
        status
        userId
        current
        withParkingAccess: with_parking_access
        withCarPlate: with_car_plate
        origoCards: origo_cards(order: { desc: createdAt }) {
          id
          status
          current
          cardNumber
          accessGranted
          privilegeRequested
          privilegeGranted
          invitationCode
          deviceId
          user {
            login
          }
          origoEndpoint: origo_endpoint {
            current
            model
            os
          }
          mysolvyTicketStatus: mysolvy_ticket_status
          mysolvyTicketNr: mysolvy_ticket_nr
          mysolvyTicketId: mysolvy_ticket_id
        }
      }
    }
  }
`
