import { useEffect, useState } from 'react'
import styled from 'styled-components'
import MobileCard from './MobileCard'
import { themeColors } from '../../const/colors'
import DeleteHIDCard from './DeleteHIDCard'
import { useTranslation } from 'react-i18next'
import { TextBody1Bold } from '../Styles/TextCustom'
import {
  IOrigoCard,
  OrigoEventName,
  GET_ORIGO_ACCOUNT,
  IGetOrigoAccountData,
  IGetOrigoAccountVars,
} from '../../graphql/hid/getOrigoAccount'
import { useLazyQuery, ApolloError } from '@apollo/client'
import { IUserDataRow } from './HIDUsersTable'
import Error from '../Shared/Error'
import { CircularProgress, Alert } from '@mui/material'

const statusesDuringRemoval = [
  OrigoEventName.CREDENTIAL_REVOKED,
  OrigoEventName.CREDENTIAL_REVOKING,
  OrigoEventName.ENDPOINT_TERMINATED,
]

const HIDCards = ({
  userData,
  refetch,
}: {
  userData: IUserDataRow
  refetch: () => void
}) => {
  const { t } = useTranslation(['hid'])
  const [errorMessage, setErrorMessage] = useState('')
  const [deletionProcess, setDeletionProcess] = useState(false)
  const [calls, setCalls] = useState(0)
  const [loading, setLoading] = useState(false)
  const [cards, setCards] = useState<IOrigoCard[]>([])
  const { login } = userData

  const [getMobileAccessAccount] = useLazyQuery<
    IGetOrigoAccountData,
    IGetOrigoAccountVars
  >(GET_ORIGO_ACCOUNT, {
    variables: { login },
    fetchPolicy: 'no-cache',
    onError: (error: ApolloError) => {
      setLoading(false)
      setErrorMessage(error.message)
    },
    onCompleted: async (data: IGetOrigoAccountData) => {
      await setCards(data?.getUser.origoAccounts[0]?.origoCards || [])
      if (!data?.getUser.origoAccounts[0]?.origoCards.length && calls <= 7) {
        setCalls(calls + 1)
        setTimeout(getMobileAccessAccount, 1500)
      } else {
        setLoading(false)
      }
    },
  })

  const refetchData = () => {
    refetch()
    getMobileAccessAccount()
  }

  useEffect(() => {
    getMobileAccessAccount()
    if (!cards.length) {
      setLoading(true)
    }
  }, [])

  if (calls > 7 && !cards.length) {
    return (
      <Container>
        <AlertStyled severity="error">{t('no_card_created')}</AlertStyled>
      </Container>
    )
  }

  if (loading) {
    return (
      <Container>
        <TextBold color={themeColors.primary}>
          {t('card_under_creation')}
        </TextBold>

        <CustomProgress size={50} />
      </Container>
    )
  }

  return (
    <>
      {cards.map((item, i) => (
        <CardWrapper current={!!item.current} key={i}>
          <MobileCard card={item} />
          {item.current &&
            !statusesDuringRemoval.includes(item.status) &&
            !deletionProcess && (
              <DeleteHIDCard
                refetch={refetchData}
                card={item}
                setDeletionProcess={setDeletionProcess}
              />
            )}
          {(statusesDuringRemoval.includes(item.status) || deletionProcess) && (
            <AlertStyled severity="error">
              {t('card_deletion_in_progress')}
            </AlertStyled>
          )}
        </CardWrapper>
      ))}
      {errorMessage && <Error errorMessage={errorMessage} />}
    </>
  )
}
export default HIDCards

const CardWrapper = styled.div<{
  current: boolean
}>`
  border-radius: 16px;
  padding: 20px 16px;
  margin: 16px 0;
  background-color: ${themeColors.paleGray};
  ${props => !!props.current && `background-color: ${themeColors.white}`}
`
const Container = styled.div`
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
`
const TextBold = styled(TextBody1Bold)`
  display: inline;
`
const CustomProgress = styled(CircularProgress)`
  color: ${themeColors.primary};
  margin: 16px;
`
const AlertStyled = styled(Alert)`
  margin-top: 16px;
`
