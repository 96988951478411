import { DatePicker } from '@mui/x-date-pickers'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import { Dayjs } from 'dayjs'

const DateRangeFilter = ({
  rangeFrom,
  rangeTo,
  setRangeFrom,
  setRangeTo,
  labelFrom,
  labelTo,
}: {
  rangeFrom: Dayjs | null
  setRangeFrom: (value: Dayjs | null) => void
  rangeTo: Dayjs | null
  setRangeTo: (value: Dayjs | null) => void
  labelFrom?: string
  labelTo?: string
}) => {
  const { t } = useTranslation(['generic'])
  return (
    <Grid container spacing={2}>
      <Grid item>
        <DatePicker
          label={labelFrom ? labelFrom : t('from')}
          value={rangeFrom}
          onChange={setRangeFrom}
        />
      </Grid>
      <Grid item>
        <DatePicker
          label={labelTo ? labelTo : t('to')}
          value={rangeTo}
          onChange={setRangeTo}
          minDate={rangeFrom || undefined}
          disabled={!rangeFrom}
        />
      </Grid>
    </Grid>
  )
}

export default DateRangeFilter
