import { gql } from '@apollo/client'
import { ID } from '../IQueryData'

export interface IGetParkingPoolProfileVars {
  id: ID
}

export interface IGetParkingPoolProfileData {
  getParkingPool: {
    id: ID
    profiles: {
      id: string
      name: string
    }[]
  }
}

export const GET_PARKING_POOL_PROFILE = gql`
  query getParkingPool($id: ID!) {
    getParkingPool(id: $id) {
      id
      profiles(filter: { not: { disabled: true } }) {
        id
        name
        disabled
      }
    }
  }
`
