import { gql } from '@apollo/client'
import { IQueryBasicData } from '../IQueryData'

export interface ICreateOrigoAccountVars {
  login: string
  withParkingAccess: boolean
  carPlate: string
  comment: string
  attachment: string
}

export interface ICreateOrigoAccountData {
  CreateMobileUserAccount: IQueryBasicData
}

export const CREATE_ORIGO_ACCOUNT = gql`
  mutation CreateMobileUserAccount(
    $login: String!
    $withParkingAccess: Boolean!
    $carPlate: String
    $comment: String
    $attachment: String
  ) {
    CreateMobileUserAccount(
      Login: $login
      WithParkingAccess: $withParkingAccess
      CarPlate: $carPlate
      Comment: $comment
      Attachment: $attachment
    ) {
      status
      code
      message
      error
      parameters {
        name
        value
      }
    }
  }
`
