import styled from 'styled-components'
import { FormControl, MenuItem, InputLabel } from '@mui/material'
import Select from '@mui/material/Select'
import { useTranslation } from 'react-i18next'
import {
  IGetParkingPoolProfileVars,
  IGetParkingPoolProfileData,
  GET_PARKING_POOL_PROFILE,
} from '../../graphql/parkingPool/getParkingPoolProfile'
import { useLazyQuery } from '@apollo/client'
import { useEffect } from 'react'
import { CircularProgress } from '@mui/material'

const ParkingPoolProfileFilter = ({
  value,
  setValue,
  id,
}: {
  value: string
  setValue: (v: string) => void
  id: string
}) => {
  const { t } = useTranslation(['parking_pool'])

  const [getProfiles, { data, loading }] = useLazyQuery<
    IGetParkingPoolProfileData,
    IGetParkingPoolProfileVars
  >(GET_PARKING_POOL_PROFILE, {
    fetchPolicy: 'no-cache',
  })

  useEffect(() => {
    getProfiles({ variables: { id } })
  }, [id])

  const handleChange = (event: any) => setValue(event.target.value)

  if (loading) {
    return <CircularProgress />
  }

  if (!data?.getParkingPool.profiles.length) {
    return null
  }

  return (
    <Container>
      <FormControl>
        <InputLabel id="select-label">{t('profile')}</InputLabel>
        <SelectStyled
          labelId="select-label"
          value={value}
          label="Profil usługi"
          onChange={handleChange}
        >
          {data?.getParkingPool.profiles.map(({ name }, i) => (
            <MenuItem key={i} value={name}>
              {name}
            </MenuItem>
          ))}
        </SelectStyled>
      </FormControl>
    </Container>
  )
}

export default ParkingPoolProfileFilter

const SelectStyled = styled(Select)`
  width: 220px;
`
const Container = styled.div`
  padding: 0 0 0 16px;
  display: inline;
`
