import { gql } from '@apollo/client'

export const REFRESH_TOKEN = gql`
  query RefreshToken(
    $refresh_token: String!
    $app: String!
    $api_version: Int!
  ) {
    refresh(
      refresh_token: $refresh_token
      app: $app
      api_version: $api_version
    ) {
      status
      code
      error
      accessToken
      refreshToken
    }
  }
`
