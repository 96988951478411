import { ApolloClient, from, InMemoryCache } from '@apollo/client'
import { refreshTokenLink } from './refreshTokenLink'
import { errorLink } from './errorLink'
import { authLink } from './authLink'
import { httpLink } from './httpLink'

export const apolloClient = new ApolloClient({
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          queryIssue: {
            merge(existing = [], incoming: any[]) {
              return incoming
            },
          },
        },
      },
    },
  }),
  link: from([refreshTokenLink, errorLink, authLink, httpLink]),
})
