import { gql } from '@apollo/client'
import { AccessCardType } from './queryUsersList'
import {
  ParkingSubscriptionStatus,
  ParkingSubscriptionType,
  ReservationStatus,
} from '../tenant/getTenant'

export interface IQueryUserVars {
  login: string
}

export interface IParkingSubscription {
  type: ParkingSubscriptionType
  validFrom: string
  validTo: string
  status: ParkingSubscriptionStatus
  reservations: {
    validFrom: string
    validTo: string
    status: ReservationStatus
    place: {
      name: string
      tenant: {
        name: string
      }
      parking: {
        name: string
      }
    }
  }[]
}

export interface IUser {
  login: string
  accessCard: {
    number: string
    type: AccessCardType
  }
  parkingPoolData: {
    id: string
    carPlate?: string
    carPlate2?: string
    enabled?: boolean
  }
  parkingPool: {
    id: string
    profiles: {
      id: string
      name: string
      disabled: boolean
    }[]
  }
  parkingPoolProfile: {
    id: string
    name: string
  }
  tenant: {
    name: string
    seos: {
      disabled: boolean
      id: string
      prefix: string
    }
  }
  parkingSubscriptions: IParkingSubscription[]
}

export interface IQueryUserData {
  queryUser: IUser[]
}

export const QUERY_USER = gql`
  query queryUser($login: String!) {
    queryUser(filter: { login: { eq: $login } }) {
      login
      accessCard: access_card {
        number
        type
      }
      parkingPoolData: parking_pool_data {
        id
        carPlate: car_plate
        carPlate2: car_plate_2
        enabled
      }
      parkingPool: parking_pool {
        id
        profiles(filter: { not: { disabled: true } }) {
          id
          name
          disabled
        }
      }
      parkingPoolProfile: parking_pool_profile {
        id
        name
      }
      tenant {
        name
        seos {
          disabled
          id
          prefix
        }
      }
      parkingSubscriptions: parking_subscriptions(
        filter: {
          type: { eq: TenantParkingPool }
          and: { not: { status: { in: [past, cancelled] } } }
        }
      ) {
        type
        validFrom: valid_from
        validTo: valid_to
        status
        reservations {
          validFrom: valid_from
          validTo: valid_to
          status
          place {
            parking {
              name
            }
            name
            tenant {
              name
            }
          }
        }
      }
    }
  }
`
