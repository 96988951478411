import React from 'react'
import {
  GET_TENANT,
  IGetTenantData,
  IGetTenantVars,
} from '../../graphql/tenant/getTenant'
import { useQuery } from '@apollo/client'
import { Alert } from '@mui/material'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useAppSelector } from '../../redux/store'

const NoPlacesAssignedAlert = () => {
  const { t } = useTranslation(['parking_pool_data'])
  const { tenantName } = useAppSelector(state => state.user)
  const { data, loading } = useQuery<IGetTenantData, IGetTenantVars>(
    GET_TENANT,
    {
      fetchPolicy: 'cache-and-network',
      variables: { name: tenantName },
    },
  )

  if (loading || data?.getTenant?.parkingPool?.isValid) {
    return null
  }

  return <AlertStyled severity="error">{t('no_valid')}</AlertStyled>
}

export default NoPlacesAssignedAlert

const AlertStyled = styled(Alert)`
  margin-bottom: 16px;
`
