import { gql } from '@apollo/client'

export interface IUpdateParkingPoolUserDataEnabledData {
  updateParkingPoolUserData: {
    parkingPoolUserData: {
      enabled: boolean
    }
  }
}

export interface IUpdateParkingPoolUserDataEnabledVars {
  id: string
  enabled: boolean
}

export const UPDATE_PARKING_POOL_USER_DATA_ENABLED = gql`
  mutation updateParkingPoolUserData($id: [ID!], $enabled: Boolean!) {
    updateParkingPoolUserData(
      input: { filter: { id: $id }, set: { enabled: $enabled } }
    ) {
      parkingPoolUserData(filter: { id: $id }) {
        id
        enabled
      }
    }
  }
`
