import { useQuery } from '@apollo/client'
import { Typography, Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import FullScreenLoader from '../../components/Shared/FullScreenLoader'
import { useAppSelector } from '../../redux/store'
import {
  GET_TENANT,
  IGetTenantData,
  IGetTenantVars,
} from '../../graphql/tenant/getTenant'
import NoPlacesAssignedAlert from '../../components/Parking/NoPlacesAssignedAlert'
import { themeColors } from '../../const/colors'
import {
  TextBody1Medium,
  TextBody1Bold,
} from '../../components/Styles/TextCustom'

const ParkingPoolData = () => {
  const { t } = useTranslation(['parking_pool_data'])
  const { tenantName } = useAppSelector(state => state.user)

  const { data, loading } = useQuery<IGetTenantData, IGetTenantVars>(
    GET_TENANT,
    {
      fetchPolicy: 'cache-and-network',
      variables: { name: tenantName },
    },
  )

  const productData = [
    {
      label: t('amano_product_id'),
      value: data?.getTenant.parkingPool?.parkingPass.productId,
    },
    {
      label: t('places_amount'),
      value: data?.getTenant.parkingPool?.placesCountContract,
    },
    {
      label: t('max_simultaneous_users'),
      value: data?.getTenant.parkingPool?.maxActiveUserCount,
    },

    {
      label: t('max_continuous_days'),
      value: data?.getTenant.parkingPool?.maxDurationDays,
    },
    {
      label: t('max_advanced_days'),
      value: data?.getTenant.parkingPool?.maxAdvanceDays,
    },
    {
      label: t('max_simultaneous_reservations'),
      value: data?.getTenant.parkingPool?.maxReservationCountPerUser,
    },
  ]

  if (loading || !data) {
    return <FullScreenLoader />
  }

  if (!data.getTenant?.parkingPool) {
    return <TypographyStyled variant="h6">{t('no_product')}</TypographyStyled>
  }

  return (
    <>
      <NoPlacesAssignedAlert />
      <Typography variant="h6" color={themeColors.primary}>
        {'Parking'}
      </Typography>

      <Container>
        {productData.map(item => (
          <Row key={item.label}>
            <Text>{`${item.label}: `}</Text>
            <TextBold>{item.value}</TextBold>
          </Row>
        ))}
      </Container>
    </>
  )
}

const TypographyStyled = styled(Typography)`
  font-weight: 600;
  padding-bottom: 1rem;
`
const Container = styled.div`
  background-color: ${themeColors.lightBlue};
  border-radius: 20px;
  padding: 16px;
  margin-top: 16px;
`
const Text = styled(TextBody1Medium)`
  display: inline;
`
const TextBold = styled(TextBody1Bold)`
  display: inline;
`
const Row = styled(Grid)`
  padding: 2px 0;
`

export default ParkingPoolData
