import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { themeColors } from '../../const/colors'
import { OrigoEventName } from '../../graphql/hid/getOrigoAccount'
import Checkbox from '../Shared/Checkbox'
import { CAR_PLATE_REGEX } from '../../const/regex'
import Input from '../Shared/Input'
import { MuiFileInput } from 'mui-file-input'
import { Controller } from 'react-hook-form'
import AttachFileIcon from '@mui/icons-material/AttachFile'

export const accountUnderCreation = [
  OrigoEventName.NEW,
  OrigoEventName.USER_CREATED,
]

const CardSettingsForm = ({
  control,
  errors,
  watch,
}: {
  control: any
  errors: any
  watch: any
}) => {
  const { t } = useTranslation(['hid'])

  const parkingAccess = watch('withParkingAccess')

  return (
    <FormWrapper>
      <CheckboxStyled
        control={control}
        errors={errors}
        name="withParkingAccess"
        data-e2e="with-parking-access"
      >
        {t('with_parking_access')}
      </CheckboxStyled>

      {parkingAccess && (
        <Input
          label={t('user_details:car_plate')}
          name={'carPlate'}
          control={control}
          errors={errors}
          rules={{
            required: `${t('form:field_required')}`,
            pattern: {
              value: CAR_PLATE_REGEX,
              message: t('form:valid_car_plate'),
            },
          }}
        />
      )}
      <Input
        label={t('comment')}
        name={'comment'}
        control={control}
        errors={errors}
        multiline
        rows={3}
      />
      <Controller
        name="attachment"
        control={control}
        render={({ field, fieldState }) => (
          <FileInputStyled
            {...field}
            label={t('add_attachment')}
            // helperText={fieldState.invalid ? 'File is invalid' : ''}
            // error={fieldState.invalid}
            InputProps={{
              startAdornment: <AttachFileIcon />,
            }}
            getInputText={value => (value ? value.name : '')}
            getSizeText={value =>
              value && value?.size > 10000000 ? 'Too big!' : ''
            }
          />
        )}
      />
    </FormWrapper>
  )
}

export default CardSettingsForm
const FormWrapper = styled.div`
  width: 500px;
`
const CheckboxStyled = styled(Checkbox)`
  margin-bottom: 16px;
`
const FileInputStyled = styled(MuiFileInput)`
  width: 100%;
`
