import { gql } from '@apollo/client'
import { OrigoEventName } from './getOrigoAccount'

export interface IHIDUser {
  login: string
  firstName: string
  lastName: string
  createdAt: string
  email: { email: string }
  workEmail: { email: string } | null
  tenant: {
    name: string
    id: string
    slug: string
  }
  origoAccounts: {
    userId: string
    status: OrigoEventName
    origoCards: {
      cardNumber: number
      status: string
      accessGranted: boolean
      deviceId: string
    }[]
  }[]
}

export interface IQueryUserVars {
  tenant: string
}

export interface IQueryUserData {
  queryUser: IHIDUser[]
}

export const QUERY_USER_LIST = gql`
  query queryUserList($tenant: String) {
    queryUser(filter: { not: { disabled: true } })
      @cascade(fields: ["tenant"]) {
      login
      firstName: first_name
      lastName: last_name
      email {
        email
      }
      workEmail: work_email {
        email
      }
      tenant(filter: { name: { eq: $tenant } }) {
        name
        id
        slug
      }
      origoAccounts: origo_accounts(filter: { current: true }) {
        userId
        status
        current
        origoCards: origo_cards(filter: { current: true }) {
          cardNumber
          status
          accessGranted
          deviceId
        }
      }
    }
  }
`
