import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import CloseButton from '../Shared/CloseButton'
import FullScreenLoader from '../Shared/FullScreenLoader'
import { CustomHeader } from '../Styles/DetailsDrawersStyles'
import UserBaseInfo from '../UserDetails/UserBaseInfo'
import { IUserDataRow } from '../Parking/ParkingPoolUsersTable'
import AccessCard from '../UserDetails/AccessCard'
import { useQuery } from '@apollo/client'
import UserParkingReservations from '../Parking/UserParkingReservations'
import {
  IQueryUserData,
  IQueryUserVars,
  QUERY_USER,
} from '../../graphql/users/queryUser'
import AddParkingPoolData from './AddParkingPoolData'
import EditParkingPoolCarPlates from './EditParkingPoolCarPlates'
import {
  CHECK_USER_PARKING_POOL_ASSIGNMENT,
  ICheckUserParkingPoolAssignmentVars,
  ICheckUserParkingPoolAssignmentData,
} from '../../graphql/parkingPool/checkUserAssignment'
import { ParkingSubscriptionStatus } from '../../graphql/tenant/getTenant'
import AssignUserToParkingPool from './AssignUserToParkingPool'
import ParkingPoolProfiles from './ParkingPoolProfiles'

const UserDetails: FC<{
  userData: IUserDataRow
  closeDrawer: () => void
  listRefetch: () => void
  tenantName: string
  parkingPoolId: string
}> = ({ userData, closeDrawer, listRefetch, tenantName, parkingPoolId }) => {
  const { t } = useTranslation(['user_details', 'tenant'])
  const [assigned, setAssigned] = useState(false)
  const activeStatuses = [
    ParkingSubscriptionStatus.active,
    ParkingSubscriptionStatus.pending,
    ParkingSubscriptionStatus.new,
  ]

  const { loading: checkLoading, refetch: refetchCheck } = useQuery<
    ICheckUserParkingPoolAssignmentData,
    ICheckUserParkingPoolAssignmentVars
  >(CHECK_USER_PARKING_POOL_ASSIGNMENT, {
    variables: { tenant: tenantName, login: userData.login },
    fetchPolicy: 'no-cache',
    onCompleted: (data: ICheckUserParkingPoolAssignmentData) => {
      const isUserAssigned = !!data?.getTenant.parkingPool?.users.length
      setAssigned(isUserAssigned)
    },
  })

  const { data, loading, refetch } = useQuery<IQueryUserData, IQueryUserVars>(
    QUERY_USER,
    {
      variables: { login: userData.login },
      fetchPolicy: 'cache-and-network',
    },
  )

  if (loading || checkLoading || !data) {
    return <FullScreenLoader />
  }

  const { login, tenant, parkingPoolData, accessCard, parkingSubscriptions } =
    data.queryUser[0]

  const activeSubscription = parkingSubscriptions.find(({ status }) =>
    activeStatuses.includes(status),
  )

  return (
    <>
      <Grid container alignItems="center">
        <CloseButton onClick={closeDrawer} />
        <CustomHeader>{t('title')}</CustomHeader>
      </Grid>

      <UserBaseInfo userData={userData} />

      <AccessCard
        accessCard={accessCard}
        login={login}
        seos={tenant.seos ? !tenant.seos?.disabled : false}
        refetch={refetch}
      />

      <>
        {!!parkingPoolData ? (
          <EditParkingPoolCarPlates
            parkingPoolData={parkingPoolData}
            refetch={refetch}
            listRefetch={listRefetch}
          />
        ) : (
          <AddParkingPoolData
            login={login}
            accessCard={accessCard?.number}
            refetch={refetch}
            listRefetch={listRefetch}
          />
        )}
        <AssignUserToParkingPool
          refetch={refetchCheck}
          listRefetch={listRefetch}
          assigned={assigned}
          login={login}
          id={parkingPoolId}
          disabled={!accessCard || !parkingPoolData}
          parkingPoolDataId={parkingPoolData?.id}
          activeSubscription={!!activeSubscription}
        />
        {!!assigned && (
          <ParkingPoolProfiles
            refetch={refetchCheck}
            listRefetch={listRefetch}
            assigned={assigned}
            user={data.queryUser[0]}
          />
        )}

        {!!parkingSubscriptions.length && (
          <UserParkingReservations subscriptions={parkingSubscriptions} />
        )}
      </>
    </>
  )
}

export default UserDetails
