import styled from 'styled-components'
import { Button } from '@mui/material'

interface IBaseLinkProps {
  path: string
  text: string
}

const BaseLink = (props: IBaseLinkProps) => {
  const { path, text } = props

  return (
    <Link href={path} target="_blank">
      <Button color="primary" variant="outlined" size="large">
        {text}
      </Button>
    </Link>
  )
}
export default BaseLink

const Link = styled.a`
  text-decoration: none;
`
