export enum Roles {
  admin = 'admin',
  developer = 'developer',
  tester = 'tester',
  office_manager = 'office_manager',
  om_hid = 'om_hid',
  om_parking_pool = 'om_parking_pool',
}

export const SIGN_IN_ROLES = [Roles.office_manager]
export const ADMIN = [Roles.admin]
export const PARKING_POOL_ROLES = [Roles.om_parking_pool]
export const HID_ROLES = [Roles.om_hid]
