import { useMemo, ReactNode, useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import RTTable from '../RTTable'
import { getBoolColor } from '../../utils/getColor'
import { IHIDUser } from '../../graphql/hid/queryUser'
import { OrigoEventName } from '../../graphql/hid/getOrigoAccount'
import {
  handleStatus,
  getStatusColor,
  handleStatusDescription,
} from '../../utils/handleMobileCardStatus'
import CardStatusLegend from './CardStatusLegend'
import { Tooltip } from '@mui/material'
import DeleteSelectedHIDCards from './DeleteSelectedHIDCards'
import { themeColors } from '../../const/colors'
import { TextButton } from '../Styles/TextCustom'
import styled from 'styled-components'

interface ITableColumns {
  Header: string
  accessor:
    | 'fullName'
    | 'login'
    | 'workEmail'
    | 'tenantName'
    | 'hidAccount'
    | 'status'
    | 'cardNumber'
}

export interface IUserDataRow {
  fullName: string
  firstName: string
  lastName: string
  login: string
  workEmail: string
  tenantName: string
  tenantId: string
  tenantSlug: string
  hidAccount: boolean
  userId: string
  accountStatus?: OrigoEventName
  status: string
  cardNumber: string | number
  accessGranted: boolean
  deviceId: string
}

const HIDUsersTable = ({
  children,
  users,
  setOpenUserDetails,
  refetch,
}: {
  children: ReactNode
  users: IHIDUser[]
  setOpenUserDetails: (v: IUserDataRow | undefined) => void
  refetch: () => void
}) => {
  const { t } = useTranslation(['hid'])
  const [selected, setSelected] = useState<IUserDataRow[]>()

  const handleSelectRows = useCallback((selectedRowsData: IUserDataRow[]) => {
    setSelected(selectedRowsData.filter(({ deviceId }) => !!deviceId))
  }, [])

  const tableColumns: ITableColumns[] = useMemo(
    () => [
      {
        Header: t('columns:full_name'),
        accessor: 'fullName',
      },
      {
        Header: t('columns:login'),
        accessor: 'login',
      },
      {
        Header: t('columns:work_email'),
        accessor: 'workEmail',
      },
      {
        Header: t('columns:company'),
        accessor: 'tenantName',
      },
      {
        Header: t('mobile_card_activity'),
        accessor: 'hidAccount',
        Cell: (props: any) => (!!props.value ? t('bool:yes') : t('bool:no')),
      },
      {
        Header: t('card_status'),
        accessor: 'status',
        Cell: (props: any) =>
          !!props.value ? (
            <Tooltip
              title={`${handleStatusDescription(
                props.value,
                props.row.original.accessGranted,
              )}`}
              arrow
            >
              <span>
                {handleStatus(props.value, props.row.original.accessGranted)}
              </span>
            </Tooltip>
          ) : (
            ''
          ),
      },
      {
        Header: t('card_number'),
        accessor: 'cardNumber',
      },
    ],
    [t],
  )

  const tableData: IUserDataRow[] = useMemo(
    () =>
      users.map(
        ({
          login,
          firstName,
          lastName,
          email,
          workEmail,
          tenant,
          origoAccounts,
        }) => ({
          login,
          firstName,
          lastName,
          fullName: `${firstName} ${lastName}`,
          workEmail: workEmail?.email ? workEmail.email : email.email,
          tenantName: tenant.name || '',
          tenantId: tenant.id || '',
          tenantSlug: tenant.slug,
          hidAccount: !!origoAccounts[0]?.origoCards[0]?.deviceId,
          userId: !!origoAccounts.length ? origoAccounts[0].userId : '',
          accountStatus: origoAccounts[0]?.status,
          status: origoAccounts[0]?.origoCards[0]?.status || '',
          cardNumber: origoAccounts[0]?.origoCards[0]?.cardNumber || '',
          accessGranted: !!origoAccounts[0]?.origoCards[0]?.accessGranted,
          deviceId: origoAccounts[0]?.origoCards[0]?.deviceId || '',
        }),
      ) || [],
    [users],
  )

  return (
    <RTTable
      columns={tableColumns}
      data={tableData}
      onRowClick={rowData => setOpenUserDetails(rowData)}
      pagination
      orderByProps="fullName"
      sortDesc={false}
      rowSelect
      csvExport
      getCellProps={({ column, row }) =>
        column.id === 'hidAccount'
          ? {
              style: {
                color: getBoolColor(row.original.hidAccount),
                fontWeight: 600,
              },
            }
          : column.id === 'status'
          ? {
              style: {
                color: getStatusColor(
                  row.original.status,
                  row.original.accessGranted,
                ),
                fontWeight: 600,
              },
            }
          : {}
      }
      onSelectionChange={handleSelectRows}
    >
      {children}
      {selected?.length ? (
        <DeleteSelectedHIDCards items={selected} refetch={refetch} />
      ) : (
        <Wrapper>
          <Tooltip title={t('delete_cards_tip')}>
            <TextButtonStyled color={themeColors.lightGray}>
              {t('delete_HID_cards')}
            </TextButtonStyled>
          </Tooltip>
        </Wrapper>
      )}
    </RTTable>
  )
}

export default HIDUsersTable

const TextButtonStyled = styled(TextButton)`
  display: inline;
`
const Wrapper = styled.div`
  padding: 16px;
`
