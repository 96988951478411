import { gql } from '@apollo/client'
import { ID } from '../IQueryData'

export enum ParkingSubscriptionType {
  Personal = 'Personal',
  TenantParkingPool = 'TenantParkingPool',
}

export enum ParkingSubscriptionStatus {
  active = 'active',
  cancelled = 'cancelled',
  error = 'error',
  new = 'new',
  past = 'past',
  pending = 'pending',
}

export enum ReservationStatus {
  cancelled = 'cancelled',
  error = 'error',
  finished = 'finished',
  prereserved = 'prereserved',
  rented = 'rented',
  reserved = 'reserved',
}

export interface IParkingPoolSubscription {
  type: ParkingSubscriptionType
  validFrom: string
  validTo: string
  status: ParkingSubscriptionStatus
  parkingPass: {
    parking: {
      name: string
    }
  }
  reservations: {
    validFrom: string
    validTo: string
    status: ReservationStatus
    place: {
      name: string
      tenant: {
        name: string
      }
      shared: boolean
      parking: {
        name: string
      }
    }
  }[]
  user: {
    login: string
    firstName: string
    lastName: string
    email: {
      email: string
    }
    workEmail: {
      email: string
    }
    accessCard: {
      number: string
    }
  }
}

export interface IGetTenantVars {
  name: string
}

export interface IGetTenantData {
  getTenant: {
    parkingPool?: {
      showPlaceNames: boolean
      parkingPass: {
        productId: number
        parking: {
          name: string
        }
      }
      maxActiveUserCount: number
      maxAdvanceDays: number
      maxDurationDays: number
      maxReservationCountPerUser: number
      placesCountContract: number
      placesAggregate: {
        count: number
      }
      disabled: boolean
      isValid: boolean
      id: ID
      users: {
        login: string
        carPlates: {
          name: string
        }[]
        firstName: string
        lastName: string
        email: {
          email: string
        }
        workEmail: {
          email: string
        }
        parkingSubscriptions: IParkingPoolSubscription[]
      }[]
    }
  }
}

export const GET_TENANT = gql`
  query getTenant($name: String) {
    getTenant(name: $name) {
      name
      parkingPool: parking_pool {
        showPlaceNames: show_place_names
        disabled
        isValid: is_valid
        maxActiveUserCount: max_active_user_count
        maxAdvanceDays: max_advance_days
        maxDurationDays: max_duration_days
        maxReservationCountPerUser: max_reservation_count_per_user
        placesCountContract: places_count_contract
        placesAggregate {
          count
        }
        parkingPass: parking_pass {
          productId: amano_productno
          parking {
            name
          }
        }
        id
        users {
          firstName: first_name
          lastName: last_name
          email {
            email
          }
          workEmail: work_email {
            email
          }
          login
          parkingSubscriptions: parking_subscriptions(
            filter: { type: { eq: TenantParkingPool } }
          ) {
            type
            validFrom: valid_from
            validTo: valid_to
            status
            parkingPass: parking_pass {
              parking {
                name
              }
            }
            reservations {
              validFrom: valid_from
              validTo: valid_to
              status
              place {
                parking {
                  name
                }
                shared
                name
                tenant {
                  name
                }
              }
            }
            user {
              firstName: first_name
              lastName: last_name
              email {
                email
              }
              workEmail: work_email {
                email
              }
              login
              accessCard: access_card {
                number
              }
            }
          }
          carPlates: car_plates {
            name
            id
          }
        }
      }
    }
  }
`
