import { gql } from '@apollo/client'
import { ID } from '../IQueryData'

export enum AccessCardType {
  prox40 = 'prox40',
  prox816 = 'prox816',
  seos = 'seos',
}

export interface IParkingPoolUser {
  login: string
  firstName: string
  lastName: string
  createdAt: string
  email: { email: string } | null
  workEmail: { email: string } | null
  tenant: {
    name: string
    id: string
    seos?: {
      disabled?: boolean
      id: ID
      prefix: string
    }
  }
  disabled: boolean
  accessCard: {
    number: string
    type: AccessCardType
  }
  parkingPoolData: {
    id: string
    carPlate?: string
    carPlate2?: string
    enabled?: boolean
    isValid?: boolean
  }
  parkingPoolProfile: {
    id: string
    name: string
  }
}

export interface IQueryUsersVars {
  tenant: string
}

export interface IQueryUsersData {
  queryUser: IParkingPoolUser[]
}

export const QUERY_USERS_LIST = gql`
  query queryUsersList($tenant: String) {
    queryUser(filter: { not: { disabled: true } })
      @cascade(fields: ["tenant"]) {
      login
      firstName: first_name
      lastName: last_name
      disabled
      email {
        email
      }
      workEmail: work_email {
        email
      }
      tenant(filter: { name: { eq: $tenant } }) {
        name
        id
        seos {
          disabled
          id
          prefix
        }
      }
      accessCard: access_card {
        number
        type
      }
      parkingPoolData: parking_pool_data {
        id
        carPlate: car_plate
        carPlate2: car_plate_2
        enabled
        isValid: is_valid
      }
      parkingPoolProfile: parking_pool_profile {
        id
        name
      }
    }
  }
`
