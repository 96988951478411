const options: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
}

export const dateIso2localeString = (
  date: string | Date,
  lang: string,
  option = options,
): string => {
  const locale = lang.substr(0, 2) === 'en' ? 'en-GB' : lang
  const formattedDate = new Date(date)
  return formattedDate.toLocaleDateString(locale, option)
}

export const date2isoString = (date: Date, short?: boolean): string => {
  const isoString = date.toISOString()
  return short ? isoString.split('T')[0] : isoString
}

export const date2isoStringWithTimezone = (date: Date): string => {
  const timezoneOffset = new Date().getTimezoneOffset() * 60000
  return new Date(date.getTime() - timezoneOffset).toISOString()
}

export const date2localeString = (
  date: Date,
  lang: string,
  option = options,
): string => {
  const locale = lang.substr(0, 2) === 'en' ? 'en-GB' : lang
  return date.toLocaleDateString(locale, option)
}

export const dateIso2ExcelFormat = (date: string): string => {
  let d = new Date(date),
    minute = '' + d.getMinutes(),
    hour = '' + d.getHours(),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear()

  if (month.length < 2) month = '0' + month
  if (day.length < 2) day = '0' + day

  return (
    [year, month, day].join('-') +
    ' ' +
    [hour, minute.padStart(2, '0')].join(':')
  )
}
