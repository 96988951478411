import React from 'react'
import { IParkingSubscription } from '../../graphql/users/queryUser'
import { TextBody1Bold, TextBody1Medium, Title } from '../Styles/TextCustom'
import { useTranslation } from 'react-i18next'
import { dateIso2localeString } from '../../utils/formatDate'
import styled from 'styled-components'
import { themeColors } from '../../const/colors'
import { Paper } from '@mui/material'

interface IProps {
  subscriptions: IParkingSubscription[]
}

const UserParkingReservations = ({ subscriptions }: IProps) => {
  const { t } = useTranslation(['user_details', 'columns'])
  return (
    <PaperSection>
      <Title>{t('admin_menu:reservations')}</Title>
      {subscriptions.map((val, i) => (
        <ReservationContainer key={i}>
          <div>
            <TextBody1Medium>
              {t('columns:valid_from')}:{' '}
              <TextBody1Bold>
                {dateIso2localeString(val.validFrom, 'pl', {})}
              </TextBody1Bold>
            </TextBody1Medium>
          </div>
          <div>
            <TextBody1Medium>
              {t('columns:valid_to')}:{' '}
              <TextBody1Bold>
                {dateIso2localeString(val.validTo, 'pl', {})}
              </TextBody1Bold>
            </TextBody1Medium>
          </div>
          <div>
            <TextBody1Medium>
              {t('columns:parking')}:{' '}
              <TextBody1Bold>
                {val.reservations[0].place.parking.name}
              </TextBody1Bold>
            </TextBody1Medium>
          </div>
          <div>
            <TextBody1Medium>
              {t('columns:place')}:{' '}
              <TextBody1Bold>{val.reservations[0].place.name}</TextBody1Bold>
            </TextBody1Medium>
          </div>
          <div>
            <TextBody1Medium>
              {t('columns:subscription_status')}:{' '}
              <TextBody1Bold>{val.status}</TextBody1Bold>
            </TextBody1Medium>
          </div>
        </ReservationContainer>
      ))}
    </PaperSection>
  )
}

export default UserParkingReservations

const ReservationContainer = styled.div`
  margin-bottom: 8px;
  margin-top: 8px;
  padding-bottom: 8px;
  border-bottom: 1px solid ${themeColors.lightGray};
`
const PaperSection = styled.div`
  background-color: ${themeColors.white};
  border-radius: 16px;
  padding: 20px 16px;
  margin: 16px 0;
`
