import { useQuery } from '@apollo/client'
import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import FullScreenLoader from '../Shared/FullScreenLoader'
import { useAppSelector } from '../../redux/store'
import {
  GET_HID_CONFIG,
  IGetHIDConfigData,
  IGetHIDConfigVars,
} from '../../graphql/hid/getHIDConfig'
import { themeColors } from '../../const/colors'
import { TextBody1Medium, TextBody1Bold } from '../Styles/TextCustom'

const HIDServiceData = () => {
  const { t } = useTranslation(['hid'])
  const { tenantName } = useAppSelector(state => state.user)

  const { data, loading } = useQuery<IGetHIDConfigData, IGetHIDConfigVars>(
    GET_HID_CONFIG,
    {
      fetchPolicy: 'no-cache',
      variables: {
        name: tenantName,
      },
    },
  )

  const productData = [
    {
      label: t('number_of_cards'),
      value: data?.getTenant.hidConfig?.maxNumberOfMobileCards,
    },
    { label: t('used_cards'), value: data?.getTenant.employees.length },
    {
      label: t('active_cards'),
      value: data?.getTenant.employees.filter(
        ({ accounts }) => !!accounts[0].origoCards[0]?.deviceId,
      ).length,
    },
  ]

  if (loading) {
    return <FullScreenLoader />
  }

  if (!data?.getTenant?.hidConfig) {
    return (
      <TypographyStyled variant="h6">
        {t('no_hid_account_assigned')}
      </TypographyStyled>
    )
  }

  return (
    <Container>
      {productData.map(item => (
        <Wrapper key={item.label}>
          <Text>{`${item.label}: `}</Text>
          <TextBold>{item.value}</TextBold>
        </Wrapper>
      ))}
    </Container>
  )
}

const TypographyStyled = styled(Typography)`
  font-weight: 600;
  padding-bottom: 1rem;
`

const Container = styled.div`
  background-color: ${themeColors.lightBlue};
  border-radius: 20px;
  padding: 16px;
  margin-bottom: 16px;
  width: 50%;
  display: flex;
  flex-direction: row;
`

const Wrapper = styled.div`
  padding: 0 32px 0 0;
`
const Text = styled(TextBody1Medium)`
  display: inline;
`
const TextBold = styled(TextBody1Bold)`
  display: inline;
`

export default HIDServiceData
