import { FC } from 'react'
import { Paper, Grid } from '@mui/material'
import styled from 'styled-components'
import { TextBody1Medium, TextBody1Bold } from '../Styles/TextCustom'
import { useTranslation } from 'react-i18next'
import { IUserDataRow } from '../Parking/ParkingPoolUsersTable'
import { themeColors } from '../../const/colors'

const UserBaseInfo: FC<{ userData: IUserDataRow | any }> = ({ userData }) => {
  const { t } = useTranslation(['user_details', 'form', 'btn'])
  const { login, workEmail, firstName, lastName } = userData

  const readOnlyData = [
    { label: t('form:login'), value: login },
    { label: t('form:work_email'), value: workEmail },
    { label: t('form:first_name'), value: firstName },
    { label: t('form:last_name'), value: lastName },
  ]

  return (
    <PaperSection>
      {readOnlyData.map(
        item =>
          item.value && (
            <Row key={item.label}>
              <Text>{`${item.label}: `}</Text>
              <TextBold>{item.value}</TextBold>
            </Row>
          ),
      )}
    </PaperSection>
  )
}

export default UserBaseInfo
const Text = styled(TextBody1Medium)`
  display: inline;
`
const TextBold = styled(TextBody1Bold)`
  display: inline;
`
const Row = styled(Grid)`
  padding: 2px 0;
`
const PaperSection = styled.div`
  background-color: ${themeColors.white};
  border-radius: 16px;
  padding: 20px 16px;
  margin: 16px 0;
`
