import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Grid } from '@mui/material'
import CloseButton from '../Shared/CloseButton'
import { CustomHeader } from '../Styles/DetailsDrawersStyles'
import UserBaseInfo from '../UserDetails/UserBaseInfo'
import { IUserDataRow } from './HIDUsersTable'
import { IHIDConfig } from '../../graphql/hid/getHIDConfig'
import HIDAccount from './HIDAccount'

const HIDUserDetails: FC<{
  userData: IUserDataRow
  closeDrawer: () => void
  listRefetch: () => void
  hidConfig?: IHIDConfig
}> = ({ userData, closeDrawer, listRefetch }) => {
  const { t } = useTranslation(['user_details', 'tenant'])

  return (
    <>
      <Container container alignItems="center">
        <CloseButton
          onClick={closeDrawer}
          data-e2e="UserDetails-button-close"
        />
        <CustomHeader>{t('title')}</CustomHeader>
      </Container>
      <UserBaseInfo userData={userData} />
      <HIDAccount userData={userData} listRefetch={listRefetch} />
    </>
  )
}

export default HIDUserDetails

const Container = styled(Grid)`
  margin-bottom: 16px;
`
