import { gql } from '@apollo/client'

export interface IAddParkingPoolUserData {
  addParkingPoolUserData: {
    numUids: number[]
  }
}

export interface IAddParkingPoolUserDataVars {
  carPlate: string
  carPlate2?: string
  enabled: boolean
  login: string
}

export const ADD_PARKING_POOL_USER_DATA = gql`
  mutation addParkingPoolUserData(
    $carPlate: String!
    $carPlate2: String
    $enabled: Boolean!
    $login: String
  ) {
    addParkingPoolUserData(
      input: {
        car_plate: $carPlate
        car_plate_2: $carPlate2
        enabled: $enabled
        user: { login: $login }
      }
    ) {
      numUids
    }
  }
`
