import React, { useEffect, useState } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import { ROUTES } from './const/routes'
import { ApolloError, useLazyQuery } from '@apollo/client'
import { Roles, SIGN_IN_ROLES } from './const/permissions'
import Home from './pages/Home'
import FullScreenLoader from './components/Shared/FullScreenLoader'
import { useAppDispatch } from './redux/store'
import { UserActionTypes } from './redux/user/types/UserActionTypes'
import NoMatch from './components/NoMatch'
import { GET_MY_ROLES, IGetMyRolesData } from './graphql/user/getMyRoles'
import AuthWrapper from './components/AuthWrapper'
import { checkSignInRoles } from './utils/checkSignInRole'
import Login from './pages/Login'
import SignedInContainer from './components/SignedInContainer'
import { useNavigate } from 'react-router-dom'

const App = () => {
  const [loading, setLoading] = useState(true)
  const [prevRoute, setPrevRoute] = useState('')
  const dispatch = useAppDispatch()
  const token = localStorage.getItem('accessToken')

  const setRoles = (roles: { name: Roles }[]) => {
    dispatch({
      type: UserActionTypes.SET_USER_ROLES,
      isAdmin: roles.map(item => item.name).includes(Roles.admin),
      roles: roles.map(role => role.name),
    })
  }
  const navigate = useNavigate()
  const prev = useLocation()

  useEffect(() => {
    if (prev.pathname === '/') {
      navigate('/login')
    } else if (prev.pathname !== '/login' && prev.pathname !== '/admin') {
      setPrevRoute(prev.pathname)
    }
  }, [prev])

  const onCompleted = (data: IGetMyRolesData) => {
    if (data?.getMyRoles) {
      if (checkSignInRoles(data.getMyRoles.roles.map(item => item.name))) {
        setRoles(data.getMyRoles.roles)
      }
    }
    setLoading(false)
  }

  const onError = (error: ApolloError) => setLoading(false)

  const [getMyRoles] = useLazyQuery<IGetMyRolesData>(GET_MY_ROLES, {
    onCompleted,
    onError,
    fetchPolicy: 'no-cache',
  })

  useEffect(() => {
    !!token ? getMyRoles() : setLoading(false)
  }, [getMyRoles, token])

  if (loading) {
    return <FullScreenLoader />
  }

  return (
    <Routes>
      <Route path={ROUTES.HOME} element={<Home />} />
      <Route path={ROUTES.LOGIN} element={<Login prevRoute={prevRoute} />} />
      <Route
        path={ROUTES.ADMIN}
        element={
          <AuthWrapper requiredRoles={SIGN_IN_ROLES}>
            <SignedInContainer />
          </AuthWrapper>
        }
      />
      <Route path="*" element={<NoMatch />} />
    </Routes>
  )
}

export default App
