import { gql } from '@apollo/client'
import { AccessCardType } from './queryUsersList'
import { IQueryBasicData } from '../IQueryData'

export interface ISetAccessCardForUserData {
  setAccessCardForUser: IQueryBasicData
}

export interface ISetAccessCardForUserVars {
  login: string
  cardType: AccessCardType
  cardNumber: string
}

export const SET_ACCESS_CARD_FOR_USER = gql`
  mutation setAccessCardForUser(
    $login: String!
    $cardType: AccessCardType!
    $cardNumber: String!
  ) {
    setAccessCardForUser(
      login: $login
      card_type: $cardType
      card_number: $cardNumber
    ) {
      status
      error
      code
      message
    }
  }
`
