const pl = {
  generic: {
    error: 'Błąd',
    ups: 'Ups!',
    understand: 'Rozumiem',
    no_options: 'Brak opcji',
    network_error: 'Błąd sieci',
    qraphql_error: 'Błąd GraphQL',
    no: 'Nie',
    maybe_later: 'Może później',
    exit_without_saving: 'Czy na pewno chcesz porzucić zmiany?',
    free: 'Darmowy',
    yes: 'Tak',
    active: 'Aktywna',
    inactive: 'Nieaktywna',
    visible: 'Widoczne',
    invisible: 'Niewidoczne',
    available: 'Dostępne',
    unavailable: 'Niedostępne',
    success_message: 'Zmiany zostały zapisane',
    selected: 'zaznaczonych',
    no_results: 'Brak wyników',
    reservation_created: 'Rezerwacja została utworzona',
    from: 'Od',
    to: 'Do',
  },
  btn: {
    log_in: 'Zaloguj się',
    go_to_app: 'Przejdź do aplikacji',
    create_new_password: 'Utwórz nowe hasło',
    confirm: 'Potwierdź',
    save: 'Zapisz',
    cancel: 'Anuluj',
    accept: 'Przyjmij',
    reject: 'Odrzuć',
    add_tenant: 'Dodaj najemcę',
    save_tenant: 'Zapisz najemcę',
    add_domain: 'Dodaj domenę',
    delete_domain: 'Usuń domenę',
    send_again: 'Wyślij ponownie zaznaczone',
    delete: 'Usuń',
    delete_selected: 'Usuń zaznaczone',
    send_message: 'Wyślij wiadomość',
    exit: 'Wyjdź',
    add_menu_item: 'Dodaj nowe danie',
    more_data: 'Więcej Danych',
    edit: 'Edytuj',
    add_dish: 'Dodaj danie',
    edit_dish: 'Edytuj danie',
    add_subscriptions_parking: 'Dodaj abonament',
    search: 'szukaj',
    filtr_clear: 'Wyczyść filtr',
    clear_filters: 'Wyczyść filtry',
    expand: 'Rozwiń',
    collapse: 'Zwiń',
    backToUser: 'Wróć do użytkownika',
    add_survey: 'Dodaj Ankietę',
    show_regulation: 'Pokaż treść regulaminu',
    hide_regulation: 'Ukryj treść regulaminu',
    add_comment: 'Dodaj komentarz',
    submit_range: 'Zatwierdź zakres dat',
    save_changes: 'Zapisz zmiany',
    logout: 'Wyloguj',
    download_csv: 'Pobierz CSV',
    show: 'Pokaż',
  },
  form: {
    password: 'Hasło',
    new_password: 'Nowe hasło',
    email_login: 'Email lub Login',
    field_required: 'Pole wymagane',
    select_tenant: 'Wybierz najemcę...',
    tenant_required: 'Musisz wybrać najemcę aby zaakceptować',
    no_tenants: 'Brak najemców',
    comment: 'Opcjonalny komentarz dla użytkownika',
    company_name: 'Nazwa firmy',
    domain_name: 'Nazwa domeny mailowej',
    login: 'Login',
    contact_email: 'Email kontaktowy',
    work_email: 'Email służbowy',
    first_name: 'Imię',
    last_name: 'Nazwisko',
    search: 'Szukaj',
    message: 'Napisz odpowiedź',
    form_error: 'Wypełnij wymagane pola',
    log_comment: 'Komentarz',
    valid_nip: 'Wprowadź poprawny NIP',
    card_number: 'Numer karty',
    valid_car_plate: 'Wprowadź poprawny numer tablicy rejestracyjnej',
    valid_card_number: 'Wpisz poprawny numer karty w formacie: 999.99999',
  },
  days: {
    Monday: 'Pn',
    Tuesday: 'Wt',
    Wednesday: 'Śr',
    Thursday: 'Czw',
    Friday: 'Pt',
    Saturday: 'Sob',
    Sunday: 'Nie',
  },
  admin_menu: {
    garden: 'Garden',
    garden_tickets: 'Bilety do ogrodu',
    gastro: 'Restauracja',
    gastro_menu: 'Menu',
    orders: 'Zamówienia',
    orders_history: 'Historia zamówień',
    parking_areas: 'Parking',
    mobile_access: 'Mobilna karta dostępowa',
    parking_sold_subscription_list: 'Lista sprzedanych abonamentów',
    payment_history: 'Historia płatności',
    parking_subscription_product_list: 'Lista produktów abonamentowych',
    parking_sold_one_time_tickets: 'Lista sprzedanych biletów jednorazowych',
    parking_places: 'Statusy miejsc parkingowych',
    parking_place_reservations: 'Rezerwacje miejsc parkingowych',
    parking_list: 'Lista parkingów',
    users: 'Użytkownicy',
    tenant_issues: 'Prośby o status rezydenta',
    users_list: 'Wszyscy użytkownicy',
    unconfirmed_emails: 'Niepotwierdzone maile',
    companies_list: 'Lista firm',
    messages: 'Wiadomości',
    minimize_menu: 'Zwiń Menu',
    surveys: 'Ankiety',
    surveys_list: 'Lista Ankiet',
    surveys_results_list: 'Ankiety wyniki',
    blocking_olivka_products: 'Blokowanie ofert Olivki',
    bike_lockers: 'Szafki rowerowe',
    lockers_list: 'Lista szafek',
    product_list: 'Lista produktów',
    group_reservation_list: 'Lista rezerwacji grupowych',
    reservation_list: 'Lista rezerwacji',
    locker_statistics: 'Statystyka',
    parking_occupancy_report: 'Raport zajętości parkingów',
    getresponse_sync: 'Synchronizacja',
    email_list: 'Lista użytkowników',
    integrations: 'Integracje',
    reservations: 'Rezerwacje parkingów firmowych',
    data: 'Dane produktu',
    occupancy_report: 'Raport zajętości',
    parking_pool_data: 'Dane produktu parkingowego',
    hid_data: 'Dane produktu mobilnego dostępu',
  },
  login_screen: {
    my: 'Moja',
    remind_password: 'Nie pamiętam hasła',
  },
  email_verification_page: {
    success: 'Twoje konto jest już aktywne',
    tip: 'Teraz możesz zalogować się w aplikacji',
    new_email_title: 'Twój nowy adres email został potwierdzony',
    new_email: 'Możesz teraz logować się za pomocą nowego maila',
  },
  recover_password_page: {
    title: 'Stwórz nowe hasło do Twojego konta',
    success:
      ' Twoje hasło zostało zmienione poprawnie, teraz możesz zalogować się w aplikacji.',
  },
  tenant_request_details: {
    title: 'Prośba o status rezydenta',
    full_name: 'Imię i nazwisko:',
    message: 'Wiadomość:',
    login: 'Login:',
    contact_email: 'Email kontaktowy:',
    company_email: 'Email służbowy:',
    accepted: 'Zaakceptowana',
    rejected: 'Odrzucona',
    reply: 'Odpowiedź:',
    close_at: 'Data zamknięcia:',
    resolved_by: 'Zweryfikowana przez:',
  },
  user_message_details: {
    title: 'Szczegóły wiadomości',
    from: 'Od:',
    email: 'Email:',
    company: 'Firma:',
    date: 'Data zgłoszenia:',
    message: 'Wiadomość:',
    success: 'Wiadomość została wysłana poprawnie',
    reply: 'Odpowiedź:',
    closeAt: 'Data zamknięcia:',
    resolved_by: 'Odpowiedział(a):',
    delete_account: 'Prośba o usunięcie konta',
    os: 'System:',
    app: 'Wersja aplikacji:',
    api: 'Wersja API:',
    backend: 'Wersja backendu:',
  },
  tenant: {
    add_new_tenant: 'Dodaj nowego najemcę',
    edit_tenant: 'Edytuj najemcę',
    tenant_domains: 'Domeny najemcy:',
    add_domain: 'Dodaj domenę:',
    confirm_title: 'Czy na pewno chcesz edytować tego najemcę?',
    add_tenant_success: 'Najemca został utworzony',
    add_domain_success: 'Domena została utworzona',
  },
  email_verification_requests: {
    send_again: 'Wyślij ponownie',
    success: 'Email z kodem weryfikacyjnym został wysłany ponownie',
    delete_request: 'Czy na pewno chcesz usunąć wybrany kod weryfikacyjny?',
    delete_selected_requests:
      'Czy na pewno chcesz usunąć wybrane kody weryfikacyjne?',
  },
  error: {
    no_app: 'Nie znaleziono aplikacji.',
  },
  code: {
    100: 'OK',
    101: 'E-mail zweryfikowany',
    102: 'Profil zmieniony',
    103: 'Hasło zmienione',
    104: 'Status rezydenta zaktualizowany',
    105: 'E-mail potwierdzony',
    106: 'Użytkownik zalogowany',
    107: 'Użytkownik zarejestrowany',
    108: 'Zmiana adresu e-mail zaakceptowana. Podążaj za instrukcjami wysłanymi na adres e-mail.',
    109: 'Status rezydenta zaakceptowany',
    110: 'Status rezydenta odrzucony',
    111: 'Status zgody został zmieniony',
    200: 'Bilet zamówiony',
    201: 'Bilet znaleziony',
    202: 'Bilet został anulowany',
    300: 'Wiadomość wysłana',
    301: 'Zgłoszenie rozwiązane',
    400: 'Kliknij link weryfikacyjny wysłany na adres email',
    998: 'Zarezerwowane - nie używać',

    999: 'Wystąpił błąd 999, spróbuj ponownie później',
    1000: 'Niepoprawny adres email',
    1001: 'Wystąpił błąd 1001, spróbuj ponownie później',
    1002: 'Niepoprawny login lub hasło',
    1003: 'Email nie jest zweryfikowany',
    1004: 'Wystąpił błąd 1004, spróbuj ponownie później',
    1005: 'Błąd weryfikacji email',
    1006: 'Niepoprawny token, zaloguj się ponownie',
    1007: 'Wystąpił błąd 1007, spróbuj ponownie później',
    1008: 'Wystąpił błąd 1008, spróbuj ponownie później',
    1009: 'Nie można dodać użytkownika',
    1010: 'Problem z weryfikacją użytkownika',
    1011: 'Data w nieodpowiednim formacie',
    1012: 'Błąd wewnętrzny - nie można pobrać danych użytkownika',
    1013: 'Nie można potwierdzić statusu rezydenta',
    1014: 'Nie można zaktualizować użytkownika',
    1015: 'Nie można zaktualizować statusu rezydenta',
    1016: 'Nie można potwierdzić maila',
    1017: 'Podany login jest już zajęty.',
    1018: 'Podany e-mail jest już zarejestrowany.',
    1019: 'Brakuje parametru: email',
    1020: 'Brakuje parametru: kod weryfikacyjny',
    1021: 'Brakuje parametru: hasło.',
    1022: 'Nie można zmienić hasła.',
    1023: 'Niepoprawny typ email.',
    1024: 'Błąd wewnętrzny - nie można przetworzyć szablonu.',
    1025: 'Nieważny lub wygasły kod weryfikacyjny.',
    1026: 'Błędny parametr: action.',
    1027: 'Niestety podany adres e-mail nie został znaleziony na naszej liście rezydentów - wybierz opcję poniżej.',
    1028: 'Nie posiadasz wystarczających uprawnień',
    1029: 'Nie można przypisać roli',
    1030: 'Nie można usunąć przypisania roli',
    1031: 'Nie można dodać najemcy',
    1032: 'Nie można zmodyfikować najemcy',
    1033: 'Nie można usunąć najemcy',
    1034: 'Nie można dodać domeny',
    1035: 'Nie można edytować domeny',
    1036: 'Nie można usunąć domeny',
    1037: 'Nie można przypisać domeny',
    1038: 'Nie można cofnąć przypisania domeny',
    1039: 'Niepoprawna nazwa serwisu',
    1040: 'Nie można wysłać wiadomości email',
    1041: 'Nie posiadamy takiego konta w naszej bazie.',
    1042: 'Login jest za krótki',
    1043: 'Login zawiera niedozwolony znak',
    1044: 'Nie można wysłać ponownie - email jest nieznany lub kod weryfikacyjny nieważny',
    1100: 'Wymagane zgody nie zostały zaakceptowane',
    2000: 'Brak aktywnego biletu',
    2001: 'Brak aktywnego biletu',
    2002: 'Błąd wewnętrzny serwera biletowego',
    2003: 'Nie możesz kupić biletu wstecz',
    2004: 'Masz już aktywny bilet',
    2005: 'Błąd wewnętrzny - nieznany parametr dla serwera biletowego',
    2006: 'Błąd wewnętrzny serwera biletów',
    2007: 'Byłeś blisko. Brak wolnego slotu na wybraną przez Ciebie datę.',
    2008: 'Nie można zamówić biletu',
    2009: 'Data niedostępna',
    2010: 'Ups... Nie możesz zarezerwować biletu z wyprzedzeniem większym niż 31 dni.',
    2011: 'Bilet został już wykorzystany',
    3000: 'Nieznany temat problemu',
    3001: 'Nie można wysłać zgłoszenia, spróbuj ponownie później',
    3002: 'Nie można pobrać zgłoszenia',
  },
  user_details: {
    title: 'Szczegóły użytkownika',
    confirm_modal_title: 'Potwierdź zmiany',
    firstName: 'Imię',
    lastName: 'Nazwisko',
    company: 'Firma',
    user_roles: 'Role użytkownika',
    no_roles: 'Brak ról użytkownika',
    choose_role: 'Wybierz role użytkownika',
    unconfirmed_user: '* Niepotwierdzony użytkownik',
    tenant_change_success: 'Najemca został przypisany',
    tenant_deleted_success: 'Przypisany najemca został usunięty',
    add_role: 'Rola została przypisana',
    delete_role: 'Rola została usunięta',
    assigned_restaurants: 'Przypisane restauracje',
    no_restaurants: 'Brak restauracji',
    choose_restaurant: 'Wybierz restaurację',
    add_restaurant: 'Restauracja została przypisana',
    delete_restaurant: 'Restauracja została usunięta',
    switch_lock_enable: 'Użytkownik odblokowany',
    switch_lock_disabled: 'Użytkownik zablokowany',
    lock: 'Blokada użytkownika',
    lock_success: 'Użytkownik został zablokowany',
    unlock_success: 'Użytkownik został odblokowany',
    parking_no_plate: 'Brak numerów rejestracyjnych',
    parking_placeHolder: 'Numery rejestracyjne',
    user_parking: 'Numery rejestracyjne samochodów użytkownika',
    acceptances: 'Zgody użytkownika:',
    global_searcher: 'Wyszukiwarka informacji:',
    add_feature: 'Moduł został przypisany',
    delete_feature: 'Moduł został usunięty',
    user_features: 'Moduły użytkownika',
    no_features: 'Brak modułów',
    choose_feature: 'Wybierz moduł',
    shared_parking_enabled: 'Funkcjonalność włączona',
    shared_parking_disabled: 'Funkcjonalność wyłączona',
    share_parking_feature: 'Współdzielenie miejsc parkingowych',
    access_card: 'Karta dostępowa',
    access_card_change_success: 'Karta dostępowa została przypisana',
    car_plate: 'Tablica rejestracyjna',
  },
  user_search: {
    garden_tickets: 'Pokaż bilety do garden',
    user_acceptance_history: 'Pokaż historie zgód użytkownika',
    parking_subcriptions: 'Pokaż abonamenty/bilety parkingowe',
    user_messages_new: 'Pokaż nowe wiadomości od użytkownika',
    user_messages_closed: 'Pokaż zamknięte wiadomości od użytkownika',
    no_questions: 'Brak zapytań',
    get_query: 'Wybierz zapytanie',
  },
  table_component: {
    download_csv: 'Pobierz CSV',
  },
  columns: {
    full_name: 'Imię i Nazwisko',
    login: 'Login',
    main_email: 'Email główny',
    work_email: 'Email służbowy',
    card_number: 'Numer karty',
    unconfirmed_email: 'Niepotwierdzony email',
    created_at: 'Data wysłania',
    company: 'Firma',
    tenant: 'Najemca',
    domains: 'Domeny mailowe',
    roles: 'Role',
    message: 'Treść wiadomości',
    message_date: 'Data zgłoszenia',
    close_date: 'Data zamknięcia',
    status: 'Status',
    visit_date: 'Data odwiedzin',
    qr_code: 'Kod QR',
    reply: 'Odpowiedź',
    creation_date: 'Data utworzenia',
    dish_name: 'Nazwa dania',
    price: 'Cena',
    availability: 'Dostępne',
    visible: 'Widoczne',
    diet_type: 'Rodzaj dania',
    category: 'Kategoria',
    last_mobile_app: 'Ostatnie logowanie z aplikacji',
    last_seen: 'Data ostatniego logowania',
    api_version: 'Wersja API',
    car_plate: 'Numery rejestracyjne samochodów',
    features: 'Moduły',
    ticket_type: 'Typ biletu',
    order_id: 'Id zamówienia',
    payment_status: 'Status płatności',
    parking_pool: 'Parking pool',
    resident: 'Rezydent',
    account_status: 'Status konta',
    card_type: 'Typ karty',
    valid_from: 'Ważny od',
    valid_to: 'Ważny do',
    reservation_status: 'Status rezerwacji',
    subscription_status: 'Status abonamentu',
    feature_enabled: 'Funkcjonalność włączona',
    place: 'Miejsce',
    user_assigned: 'Przypisany do usługi',
    profile: 'Profil',
  },
  non_data: {
    non_data: '[Brak]',
  },
  user_details_table: {
    createdAt: 'Data zmiany',
    service: 'Jaki to serwis?',
    title: 'Tytuł zgody',
    required: 'Czy zgoda jest wymagana?',
    current: 'Czy uzytkownik wyraził zgode?',
    content: 'Treść zgody',
    buttonBack: 'Zamknij i wróć do Listy Użytkowników',
    topic: 'Historia zmian uzytkownika:',
  },
  user_acceptances: {
    required: 'Czy zgoda jest wymagana?:',
    selected: 'Czy uzytkownik zaznaczył zgode?:',
    service: 'Którego modułu dotyczy zgoda:',
    current: 'Czy zgoda/regulamin aktualnie obowiązuje:',
    createdAt: 'Data pierwszej decyzji:',
    modifiedAt: 'Data ostatniej decyzji:',
  },
  bool: {
    yes: 'Tak',
    no: 'Nie',
  },
  payment_history: {
    details_title: 'Szczegóły płatności',
    payment_date: 'Data płatności',
    ticket_id: 'Id biletu',
    ticket_type: 'Typ biletu',
    ticket_start: 'Bilet od',
    ticket_stop: 'Bilet do',
    product_name: 'Nazwa produktu',
    seller_company: 'Sprzedawca',
    seller_nip: 'NIP sprzedawcy',
    invoice: 'Faktura',
    invoice_data: 'Dane do faktury',
    parking_name: 'Nazwa parkingu',
    fv: 'Faktura Vat',
    payment_id: 'Id płatności',
    account_firstname: 'Imie',
    account_lastname: 'Nazwisko',
    email: 'Email',
    nip: 'Nip',
    name: 'Nazwa firmy',
    zipcode: 'Kod pocztowy',
    street: 'Ulica',
    city: 'Miasto',
    country: 'Kraj',
    car_plate_1: 'Numer Rejestracyjny',
    car_plate_2: 'Numer Rejestracyjny',
    access_card_id: 'Numer karty OBC',
    tenant_name: 'Najemca',
    payment_value_gross: 'Cena zakupu',
    payment_history: 'Historia płatności',
    payment_status: 'Status płatności',
    subscription: 'Abonament',
    oneTimeTicket: 'Bilet jednorazowy',
    ticket_number: 'Numer biletu',
    dates: 'Daty',
    car_plates: 'Numery rejestracyjne',
    price: 'Kwota',
    created_at: 'Data utworzenia',
    payment: 'Płatność',
    lease_from: 'Najem od',
    lease_to: 'Najem do',
    building: 'Budynek',
    locker_number: 'Numer szafki',
    locker_payment_history: 'Historia płatności za szafki',
    parking_payment_history: 'Historia płatności za parking',
  },
  parking_subscriptions_products_list: {
    parking_id: 'ID Parkingu',
    parking_name: 'Nazwa Parkingu',
    product_duration: 'Czas trwania abonamentu',
    product_name: 'Nazwa produktu',
    product_id: 'Id produktu',
    product_price: 'Cena produktu',
    product_valid_from: 'Oferta dostępna od',
    product_valid_to: 'Oferta wana do',
    active: 'Czy oferta jest aktywna?',
    max_spaces_to_sell: 'Miejsc do sprzedaży',
    seller_company_name: 'Firma sprzedająca',
    product_regulations: 'Link do regulaminu i produktu',
    subscriptions_list: 'Lista produktów abonamentowych',
  },
  parking_subscription_form: {
    add_title: 'Dodaj Abonament',
    edit_title: 'Edytuj Abonament',
    name_and_price: 'Name and price',
    valid_from: 'Produkt dostępny od:',
    duration: 'Czas trwania abonamentu:',
    price: 'Cena abonamentu:',
    price_label: 'Cena',
    limit: 'Liczba miejsc parkingowych do sprzedaży:',
    max_advance:
      'Maksymalna liczba dni, w której przed użyciem biletu można dokonać zakupu:',
    min_advance:
      'Minimalna liczba dni, w której przed użyciem biletu można dokonać zakupu:',
    after_end:
      'Ile dni po wygaśnieciu biletu miejsce parkingowe będzie zarezerwowane:',
    valid_to: 'Produkt dostępny do:',
    active: 'Czy produkt jest aktywny:',
    select_parking: 'Wybierz Parking',
    select_parking_no: 'Brak Parkingów',
    rules: 'Regulamin',
    select_no_rules: 'Brak Regulaminu',
    select_rules: 'Wybierz regulamin',
    company: 'Firma sprzedająca abonament',
    select_company: 'Wybierz firmę',
    select_company_no: 'Brak firm',
    add: 'Dodaj',
    active_yes: 'Tak, produkt jest aktywny',
    active_no: 'Nie, produkt nie jest aktywny',
    date: 'Wybierz date',
    subscription: 'Nazwa Abonamentu:',
    subscription_pl: 'Polska nazwa abonamentu',
    subscription_en: 'Angielska nazwa abonamentu',
    days: 'Dni',
    places: 'Miejsc',
    show_regulation: 'Pokaż treść regulaminu',
    min_advance_others:
      'Minimalna liczba dni roboczych, w której przed użyciem biletu można dokonać zakupu, dla budynków innych niż mój:',
    sort: 'Sortowanie:',
    amano_product_no: 'Numer produktu Amano:',
    value: 'Wartość',
    number: 'Numer',
  },
  parking_list: {
    parking_list: 'Lista parkingów',
    edition_parking: 'Edycja parkingu',
    name: 'Nazwa parkingu',
    limit_place: 'Limit miejsc parkigowych',
    places: 'Miejsc',
    building: 'Lista przypisanych budynków do parkingu:',
    building_list: 'Rozwiń liste budynków',
    building_list_no: 'Brak przypisanych budynków',
    parking_places: 'Lista dedykowanych miejsc parkingowych',
    parking_places_select: 'Rozwiń liste miejsc dedykowanych',
    parking_places_select_no: 'Brak miejsc dedykowanych',
    remarks: 'Uwagi',
    is_dedicate_places: 'Czy są miejsca dedykowane?',
    dedicate_places_number: 'Liczba miejsc dedykowanych',
    parking_building: 'Budynki przypisane do parkingu',
    company: 'Firma obsługująca parking',
    company_email: 'Email do firmy obsługującej parking',
    go_to_places: 'Przejdź do miejsc parkingowych',
    date: 'Data',
    capacity: 'Pojemność',
    sold: 'Sprzedane',
    used: 'Użyte',
    parking_occupancy_report: 'Raport zajętości parkingów',
  },
  sold_parking_items: {
    valid_subscriptions_list: 'Lista aktualnych abonamentów',
    sold_one_time_tickets: 'Lista sprzedanych biletów jednorazowych',
    valid_from: 'Ważny od',
    valid_to: 'Ważny do',
    product_name: 'Nazwa produktu',
    product_id: 'Id produktu',
    car_plates: 'Numery rejestracyjne',
    car_plate: 'Numer rejestracyjny',
    status: 'Status abonamentu',
    ticket_status: 'Status biletu',
    purchase_date: 'Data zakupu',
    payment_amount: 'Kwota płatności',
    payment_status: 'Status płatności',
    payment_id: 'ID płatności OBC',
    payment_external_id: 'ID płatności PayU',
    payment_created_at: 'Data płatności',
    logs: 'Logi:',
    no_logs: 'Brak logów',
    log_message: 'Wiadomość',
    created_at: 'Data utworzenia',
    valid_to_info: 'z datą ważności równą lub wyższą:',
    details_title: 'Szczegóły abonamentu',
    user: 'Dane kupującego',
    dates: 'Daty',
    related_tickets_and_payments: 'Powiązane bilety i płatności:',
    ticket: 'Bilet',
    place: 'Miejsce',
    parameters: 'Parametry:',
    name: 'nazwa',
    type: 'typ',
    value: 'wartość',
    sync_with_amano: 'Synchronizuj z Amano',
    cancel_subscription: 'Anuluj abonament',
    sync_date_and_time: 'Data i godzina synchronizacji',
    subscription_cancelled: 'Abonament został anulowany',
    cancel_subscription_info: 'Czy na pewno chcesz anulować ten abonament?',
    ticket_number: 'Numer biletu',
    ticket_details: 'Sczegóły biletu',
    comment: 'Komentarz',
    action_comment: 'Komentarz anulowania/resynchronizacji',
    reschedule_success: 'Abonament został zsynchronizowany',
    sale_id: 'ID sprzedaży',
    tenant: 'Najemca',
    payment_value: 'Cena zakupu',
  },
  parking_subscription_status: {
    active: 'Aktywny',
    cancelled: 'Anulowany',
    error: 'Błąd',
    new: 'Nowy',
    past: 'Przeszły',
    pending: 'W toku',
  },
  filter: {
    title: 'Filtrowanie zaawansowane po kolumnie',
    choose_column: 'Wybierz kolumne',
    choose_column_no: 'Brak kolumn',
    value: 'Wpisz fraze',
  },
  feature: {
    gastro_mobile: 'Restauracja mobile',
    gastro_admin: 'Restauracja admin',
    parking_mobile: 'Parking mobile',
    parking_admin: 'Parking admin',
    olivia_news: 'Olivia news',
    survey_with_upsert: 'Ankiety z upsert',
  },
  logs: {
    logs: 'Logi:',
    no_logs: 'Brak logów',
    parameters: 'Parametry:',
    name: 'nazwa',
    type: 'typ',
    value: 'wartość',
  },
  parking_places: {
    parking_place_statuses: 'Statusy miejsc parkingowych',
    parking_place_reservations: 'Rezerwacje miejsc parkingowych',
    level: 'Poziom',
    place: 'Miejsce',
    shared: 'Współdzielone',
    lease_term: 'Okres najmu',
    company: 'Firma',
    tenant: 'Najemca',
    from: 'Od',
    to: 'Do',
    show_place_numbers: 'Pokaż numery miejsc',
    available: 'Dostępne',
    unavailable: 'Niedostępne',
    reserved: 'Zarezerwowane',
    occupied: 'Zajęte',
    to_rent: 'Do wynajęcia',
    yes: 'Tak',
    no: 'Nie',
    hide_numbers: 'Ukryj numery miejsc',
    show_numbers: 'Pokaż numery miejsc',
    unlimited: 'bezterminowo',
    change_places_status: 'Zmień status miejsc parkingowych',
    change_status: 'Zmień status',
    new_status: 'Nowy status',
    comment: 'Komentarz',
    change_status_button_hint: 'Wybierz status oraz miejsca z listy',
  },
  reservation_status: {
    ready_to_rent: 'Do wydania',
    rented_overdue: 'Do zdania',
    rented: 'Wynajęte',
    available: 'Wolne',
    unavailable: 'Niedostępne',
    broken: 'Uszkodzone',
    reserved: 'Zarezerwowane',
    prereserved: 'Wstępna rezerwacja',
    error: 'Error',
    cancelled: 'Anulowano',
    finished: 'Zakończona',
  },
  payment_status: {
    completed: 'Zakończona',
    confirmed: 'Potwierdzona',
    error: 'Błąd',
    failed: 'Nieudana',
    new: 'Nowa',
    processing: 'W toku',
    received: 'Otrzymana',
    cancelled: 'Anulowana',
  },
  parking_ticket_status: {
    new: 'Nowy',
    reserved: 'Zarezerwowany',
    processing: 'W toku',
    paid: 'Opłacony',
    cancelled: 'Anulowany',
    unavailable: 'Niedostępny',
    error: 'Błąd',
  },
  parking_pool_data: {
    amano_product_id: 'ID produktu amano',
    places_amount: 'Liczba miejsc',
    max_simultaneous_users: 'Max. liczba użytkowników',
    max_continuous_days: 'Max. liczba dni jednej rezerwacji',
    max_advanced_days: 'Max. liczba dni z wyprzedzeniem dla rezerwacji',
    max_simultaneous_reservations: 'Max. liczba jednoczesnych rezerwacji',
    no_product: 'Brak przypisanego produktu',
    no_valid:
      'Brak przypisanych miejsc parkingowych. Zgłoś problem do administratora parkingów',
  },
  parking_pool_occupancy_report: {
    date: 'Data',
    capacity: 'Liczba aktywnych użytkowników',
    sold: 'Zarezerwowane',
    used: 'Użyte',
    max_user_count: 'Maksymalna liczba użytkowników',
    max_places_count: 'Liczba miejsc wg umowy',
    places_count: 'Liczba miejsc',
  },
  hid: {
    hid_account: 'Konto mobilnej karty dostepowej',
    mobile_access_card: 'Mobilna karta dostępowa',
    create_hid_account: 'Utwórz konto HID',
    delete_hid_account: 'Usuń konto HID',
    true: 'Tak',
    false: 'Nie',
    active: 'Aktywna',
    inactive: 'Nieaktywna',
    account_created: 'Konto utworzone. Brak podpiętych kart.',
    card_number: 'Numer karty',
    privileges_requested: 'Oczekuje na uprawnienia',
    privileges_granted: 'Uprawnienia przyznane',
    access_granted: 'Dostęp przyznany',
    success_message: 'Konto zostało utworzone',
    delete_HID_account: 'Usuń konto HID',
    delete_HID_card: 'Usuń kartę HID',
    delete_HID_cards: 'Usuń wybrane karty',
    delete_account_confirmation:
      'Czy na pewno chcesz usunąć mobilne konto dostępowe uzytkownika ',
    delete_card_confirmation: 'Czy na pewno chcesz usunąć kartę o numerze ',
    delete_selected_cards_confirmation:
      'Czy na pewno chcesz usunąć karty wybranych użytkowników?',
    no_hid_account_assigned: 'Brak przypisanego konta HID',
    hid_account_deleted: 'Konto HID zostało usunięte',
    account_deletion_in_progress: 'Trwa usuwanie konta...',
    account_under_creation: 'Konto jest w trakcie tworzenia',
    card_data: 'Dane karty:',
    card_under_creation: 'Karta w trakcie tworzenia...',
    card_deletion_in_progress: 'Trwa usuwanie karty...',
    device: 'Urządzenie',
    card_deleted: 'Karta została usunięta',
    create_card: 'Utwórz kartę',
    card_settings: 'Ustawienia karty',
    with_parking_access:
      'Zaznacz, jeśli użytkownik ma już aktywny dostęp do parkingu',
    max_number_of_mobile_cards: 'Maksymalna liczba kart',
    used_cards: 'Użyte karty',
    deletion_requirement:
      'Aby usunąć kartę wymagane jest ID urządzenia, odśwież lub poczekaj aż użytkownik otworzy aplikację.',
    no_card_created: 'Karta nie została utworzona',
    no_device_warning:
      'Karta oczekuje na instalację w telefonie użytkownika. By ją zainstalować użytkownik musi uruchomić aplikację MyOlivia, kliknąć w kafel  „Karta dostępu” i postępować zgodnie z instrukcjami.',
    comment: 'Dodatkowe uwagi do zgłoszenia',
    add_attachment: 'Dodaj załącznik do zgłoszenia',
    deletion_comment: 'Opcjonalny komentarz do usunięcia karty',
    deletion_selected_cards_comment: 'Opcjonalny komentarz',
    mysolvy_task_link: 'Link do zgłoszenia w MySolvy',
    number_of_cards: 'Pula kart',
    active_cards: 'Karty aktywne',
    mobile_card_activity: 'Aktywność karty mobilnej',
    card_status: 'Status karty',
    parking_access: 'Dostęp do parkingu',
    my_solvy_task_number: 'Numer zgłoszenia MySolvy',
    no_my_solvy_number:
      'Zgłoszenie w MySolvy zostanie utworzone po instalacji karty na telefonie użytkownika.',
    card_created: 'Utworzona karta',
    delete_cards_tip:
      'Wybierz z listy użytkowników posiadających aktywne karty',
  },
  hid_status: {
    NEW: 'Inicjacja karty',
    INVITATION_PENDING: 'Zaproszenie u użytkownika',
    INVITATION_ACKNOWLEDGED: 'Aktywacja karty',
    CREDENTIAL_ISSUE_INITIATED: 'Aktywacja karty',
    ENDPOINT_ACTIVE: 'Aktywacja karty',
    CREDENTIAL_ISSUING: 'Aktywacja karty',
    CREDENTIAL_ISSUED: 'Aktywacja dostępów na karcie',
    ACTIVE: 'Karta aktywna',
    CREDENTIAL_REVOKING: 'Usuwanie karty',
    CREDENTIAL_REVOKED: 'Karta usunięta',
    ENDPOINT_TERMINATED: 'Karta usunięta',
    ENDPOINT_DELETE_INITIATED: 'Karta usunięta',
    INVITATION_EXPIRED: 'Zaproszenie wygasło',
    INVITATION_CANCEL_INITIATED: 'Zaproszenie anulowane',
    INVITATION_CANCELLED: 'Zaproszenie anulowane',
    NEW_DESC: '???',
    INVITATION_PENDING_DESC:
      'Do użytkownika wysłano zaproszenie do instalacji mobilnej karty w telefonie. ',
    ACTIVE_DESC:
      'Karta została utworzona. Dostępy zostały nadane. Gotowa do użycia.',
    ENDPOINT_ACTIVE_DESC:
      'Użytkownik zaakceptował zaproszenie do instalacji mobilnej karty w telefonie. Proces aktywacji karty użytkownika został zainicjowany.',
    CREDENTIAL_ISSUED_DESC:
      'Dane karty zapisano w telefonie  użytkownika. Trwa aktywacja dostępów dla Użytkownika',
    CREDENTIAL_REVOKING_DESC:
      'Rozpoczęto proces usuwania karty mobilnej użytkownika.',
    CREDENTIAL_REVOKED_DESC:
      'Zakończono proces usuwania karty mobilnej użytkownika.',
    collapse: 'Zwiń legendę statusów',
    expand: 'Rozwiń legendę statusów',
  },
  hid_status_description: {
    NEW: '???',
    INVITATION_PENDING:
      'Do użytkownika wysłano zaproszenie do instalacji mobilnej karty w telefonie.',
    INVITATION_ACKNOWLEDGED:
      'Użytkownik zaakceptował zaproszenie do instalacji mobilnej karty w telefonie. Proces aktywacji karty użytkownika został zainicjowany.',
    CREDENTIAL_ISSUE_INITIATED:
      'Użytkownik zaakceptował zaproszenie do instalacji mobilnej karty w telefonie. Proces aktywacji karty użytkownika został zainicjowany.',
    ENDPOINT_ACTIVE:
      'Użytkownik zaakceptował zaproszenie do instalacji mobilnej karty w telefonie. Proces aktywacji karty użytkownika został zainicjowany.',
    CREDENTIAL_ISSUING:
      'Użytkownik zaakceptował zaproszenie do instalacji mobilnej karty w telefonie. Proces aktywacji karty użytkownika został zainicjowany.',
    CREDENTIAL_ISSUED:
      'Dane karty zapisano w telefonie  użytkownika. Trwa aktywacja dostępów dla Użytkownika',
    ACTIVE:
      'Karta została utworzona. Dostępy zostały nadane. Gotowa do użycia.',
    CREDENTIAL_REVOKING:
      'Rozpoczęto proces usuwania karty mobilnej użytkownika.',
    CREDENTIAL_REVOKED:
      'Zakończono proces usuwania karty mobilnej użytkownika.',
    ENDPOINT_TERMINATED:
      'Zakończono proces usuwania karty mobilnej użytkownika.',
    ENDPOINT_DELETE_INITIATED:
      'Zakończono proces usuwania karty mobilnej użytkownika.',
  },
  parking_pool: {
    assign_user: 'Przypisz użytkownika do usługi',
    assign_user_to_profile: 'Przypisz użytkownika do profilu',
    assigned_user: 'Użytkownik przypisany',
    unassigned_user: 'Użytkownik nieprzypisany',
    no_car_plate_assigned_info:
      'Aby przypisać tablice rejestracyjne dodaj kartę dostępową',
    no_card_assigned_info:
      'Aby aktywować możliwość rezerwacji parkingu przez użytkownika dodaj kartę dostępową oraz tablicę rejestracyjną',
    edit_car_plates: 'Edytuj tablice rejestracyjne',
    active_subscription_warning:
      'Użytkownik posiada aktywny abonament. Czy na pewno chcesz odłączyć tego użytkownika od usługi?',
    unassign_user: 'Odłącz',
    assigned_users: 'Przypisani do usługi',
    no_users: 'Brak użytkowników',
    assign_profile_info:
      'Aby wybrać profil, należy najpierw przypisać użytkownika do usługi.',
    profile: 'Profil usługi',
    choose_profile: 'Wybierz profil',
    no_profile: 'Brak profilu',
    assign_error: 'Wystąpił błąd przypisania użytkownika',
    unassign_error: 'Wystąpił błąd odłączenia użytkownika',
    assign_profile_error: 'Wystąpił błąd przypisania profilu',
    unassign_profile_error: 'Wystąpił błąd usunięcia profilu',
  },
}

export default pl
