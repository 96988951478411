import { gql } from '@apollo/client'

export interface IUnassignUserFromParkingPoolProfileData {
  updateParkingPoolProfile: {
    numUids: number[]
    parkingPoolProfile: {
      users: {
        login: string
      }[]
    }[]
  }
}

export interface IUnassignUserFromParkingPoolProfileVars {
  id: string
  login: string
}

export const UNASSIGN_USER_FROM_PARKING_POOL_PROFILE = gql`
  mutation updateParkingPoolProfile($id: ID!, $login: String) {
    updateParkingPoolProfile(
      input: { filter: { id: [$id] }, remove: { users: { login: $login } } }
    ) {
      numUids
      parkingPoolProfile @cascade(fields: "users") {
        users(filter: { login: { eq: $login } }) {
          login
        }
      }
    }
  }
`
