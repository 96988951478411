import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Title } from '../Styles/TextCustom'
import { TextBody2 } from '../Styles/TextCustom'
import { BaseButton } from '../Shared/BaseButton'
import {
  IAssignUserToParkingPoolProfileData,
  IAssignUserToParkingPoolProfileVars,
  ASSIGN_USER_TO_PARKING_POOL_PROFILE,
} from '../../graphql/parkingPool/assignUserToProfile'
import {
  IUnassignUserFromParkingPoolProfileData,
  IUnassignUserFromParkingPoolProfileVars,
  UNASSIGN_USER_FROM_PARKING_POOL_PROFILE,
} from '../../graphql/parkingPool/unassignUserFromProfile'
import { ApolloError, useMutation } from '@apollo/client'
import { toast } from 'react-toastify'
import { themeColors } from '../../const/colors'
import { IUser } from '../../graphql/users/queryUser'
import ParkingPoolProfileSelect from './ParkingPoolProfileSelect'
import Error from '../Shared/Error'

interface IProfileOption {
  value: string
  label: string
}

const ParkingPoolProfiles = ({
  refetch,
  listRefetch,
  assigned,
  user,
}: {
  refetch: () => void
  listRefetch: () => void
  assigned: boolean
  user: IUser
}) => {
  const { t } = useTranslation(['parking_pool'])
  const [errorMessage, setErrorMessage] = useState('')
  const [profile, setProfile] = useState<IProfileOption>()
  const successNotify = (message: string) => toast.dark(message)
  const { parkingPool, parkingPoolProfile, login } = user

  const onError = (err: ApolloError) => setErrorMessage(err.message)

  const onAssignCompleted = (data: IAssignUserToParkingPoolProfileData) => {
    const { parkingPoolProfile } = data.updateParkingPoolProfile

    if (!!parkingPoolProfile?.length) {
      refetch()
      listRefetch()
      successNotify(`${t('generic:success_message')}`)
    } else {
      setErrorMessage(t('assign_profile_error'))
    }
  }

  const onUnassignCompleted = (
    data: IUnassignUserFromParkingPoolProfileData,
  ) => {
    const { parkingPoolProfile } = data.updateParkingPoolProfile

    if (!parkingPoolProfile?.length) {
      refetch()
      listRefetch()
      successNotify(`${t('generic:success_message')}`)
    } else {
      setErrorMessage(t('unassign_profile_error'))
    }
  }

  const [assignUserToProfile] = useMutation<
    IAssignUserToParkingPoolProfileData,
    IAssignUserToParkingPoolProfileVars
  >(ASSIGN_USER_TO_PARKING_POOL_PROFILE, {
    onCompleted: onAssignCompleted,
    onError,
  })
  const [unassignUserFromProfile] = useMutation<
    IUnassignUserFromParkingPoolProfileData,
    IUnassignUserFromParkingPoolProfileVars
  >(UNASSIGN_USER_FROM_PARKING_POOL_PROFILE, {
    onCompleted: onUnassignCompleted,
    onError,
  })

  const handleSubmit = () => {
    setErrorMessage('')
    if (profile?.value && profile.value !== 'no_profile') {
      assignUserToProfile({
        variables: {
          id: profile.value,
          login,
        },
      })
    } else if (profile?.value === 'no_profile') {
      handleUnassignUser()
    }
  }

  const handleUnassignUser = () => {
    unassignUserFromProfile({
      variables: {
        id: parkingPoolProfile.id,
        login,
      },
    })
  }

  useEffect(() => {
    if (parkingPoolProfile) {
      setProfile({
        value: parkingPoolProfile.id,
        label: parkingPoolProfile.name,
      })
    }
  }, [parkingPool])

  if (!parkingPool?.profiles.length) {
    return null
  }

  return (
    <PaperSection>
      <Title>{t('assign_user_to_profile')}</Title>
      <ParkingPoolProfileSelect
        value={profile}
        setValue={setProfile}
        parkingPoolId={parkingPool.id}
      />
      <BaseButton onClick={handleSubmit} disabled={!assigned}>
        {t('btn:save')}
      </BaseButton>

      {!assigned && (
        <CardInfo color={themeColors.gray}>{t('assign_profile_info')}</CardInfo>
      )}
      {errorMessage && <Error errorMessage={errorMessage} />}
    </PaperSection>
  )
}

export default ParkingPoolProfiles

const PaperSection = styled.div`
  background-color: ${themeColors.white};
  border-radius: 16px;
  padding: 20px 16px;
  margin: 16px 0;
`
const CardInfo = styled(TextBody2)`
  margin-top: 16px;
  display: block;
`
