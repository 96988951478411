export const ROUTES = {
  HOME: '/',
  LOGIN: '/login',
  ADMIN: 'admin/*',
  PARKING_POOL: 'parking',
  PARKING_POOL_USERS: 'parking/users',
  PARKING_POOL_OCCUPANCY_REPORT: 'parking/occupancy-report',
  PARKING_POOL_RESERVATIONS: 'parking/reservations',
  PARKING_POOL_DATA: 'parking/data',
  HID_USERS: 'hid/users',
  HID_DATA: 'hid/data',
}
