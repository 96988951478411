import Input from '../Shared/Input'
import { BaseButton } from '../Shared/BaseButton'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'
import { AccessCardType } from '../../graphql/users/queryUsersList'
import {
  ISetAccessCardForUserData,
  ISetAccessCardForUserVars,
  SET_ACCESS_CARD_FOR_USER,
} from '../../graphql/users/setAccessCardForUser'
import { Title } from '../Styles/TextCustom'
import { useEffect, useState } from 'react'
import BaseSelect from '../Shared/BaseSelect'
import styled from 'styled-components'
import { toast } from 'react-toastify'
import { accessCardPlaceholder } from '../../utils/accessCardPlaceholder'
import { themeColors } from '../../const/colors'
import Error from '../Shared/Error'
import { PROX186_CARD_REGEX } from '../../const/regex'

type FormData = {
  cardNumber: string
  cardType: AccessCardType
}

export interface IAccessCard {
  number: string
  type: AccessCardType
}

interface IProps {
  login: string
  seos?: boolean
  accessCard?: IAccessCard
  refetch: () => void
}

const AccessCard = ({ login, seos, accessCard, refetch }: IProps) => {
  const { t } = useTranslation(['user_details', 'tenant'])
  const [cardType, setCardType] = useState<AccessCardType>()
  const [errorMessage, setErrorMessage] = useState('')
  const successNotify = (message: string) => toast.dark(message)

  const onSetAccessCardCompleted = (data: ISetAccessCardForUserData) => {
    const { status, code, error } = data.setAccessCardForUser
    if (status) {
      refetch()
      successNotify(`${t('user_details:access_card_change_success')}`)
    } else
      error &&
        setErrorMessage(
          t(`code:${code}`) === `${code}` ? `${error}` : t(`code:${code}`),
        )
  }

  const [setAccessCardForUser] = useMutation<
    ISetAccessCardForUserData,
    ISetAccessCardForUserVars
  >(SET_ACCESS_CARD_FOR_USER, {
    onCompleted: onSetAccessCardCompleted,
    onError: error => console.log(error),
  })

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<FormData>()

  useEffect(() => {
    if (accessCard) {
      setCardType(accessCard.type)
    } else {
      setCardType(undefined)
    }
  }, [accessCard])
  const updateUserAccessCard = async (data: FormData) => {
    if (cardType && data.cardNumber) {
      await setAccessCardForUser({
        variables: {
          login,
          cardNumber: data.cardNumber,
          cardType,
        },
      })
    }
  }

  const handleSelectChange = (option: any) => {
    setCardType(option?.value)
  }

  const cardTypes = seos
    ? [{ value: AccessCardType.seos, label: AccessCardType.seos }]
    : [
        {
          value: AccessCardType.prox816,
          label: AccessCardType.prox816,
        },
        {
          value: AccessCardType.prox40,
          label: AccessCardType.prox40,
        },
      ]

  const customValidation = () => {
    let basic: any = { required: `${t('form:field_required')}` }
    if (cardType === AccessCardType.prox816) {
      basic = {
        required: `${t('form:field_required')}`,
        pattern: {
          value: PROX186_CARD_REGEX,
          message: t('form:valid_card_number'),
        },
      }
    }
    return basic
  }

  return (
    <PaperSection>
      <Title>{t('access_card')}</Title>
      <InputContainer>
        <BaseSelect
          placeholder={t('card_type')}
          name="cardType"
          options={cardTypes}
          value={{ value: cardType, label: cardType }}
          onChange={handleSelectChange}
        />
      </InputContainer>
      <InputContainer>
        <Input
          label={'Card number'}
          name={'cardNumber'}
          control={control}
          errors={errors}
          placeholder={accessCardPlaceholder(cardType)}
          defaultValue={accessCard?.number || ''}
          rules={customValidation()}
        />
      </InputContainer>
      <BaseButton onClick={handleSubmit(updateUserAccessCard)}>
        {t('btn:save')}
      </BaseButton>
      {errorMessage && <Error errorMessage={errorMessage} />}
    </PaperSection>
  )
}

export default AccessCard

const InputContainer = styled.div`
  margin: 16px 0;
  max-width: 500px;
`
const PaperSection = styled.div`
  background-color: ${themeColors.white};
  border-radius: 16px;
  padding: 20px 16px;
  margin: 16px 0;
`
