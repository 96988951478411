import gql from 'graphql-tag'
import { IQueryBasicData } from '../IQueryData'

export interface IDeleteMobileUserAccountVars {
  login: string
  deviceId: string
  comment?: string
}

export interface IDeleteMobileUserAccountData {
  DeleteMobileUserAccount: IQueryBasicData
}

export const DELETE_MOBILE_USER_ACCOUNT = gql`
  mutation DeleteMobileUserAccount(
    $login: String!
    $deviceId: String!
    $comment: String
  ) {
    DeleteMobileUserAccount(
      Login: $login
      DeviceId: $deviceId
      Comment: $comment
    ) {
      status
      code
      error
      message
    }
  }
`
