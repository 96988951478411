import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Title } from '../Styles/TextCustom'
import { Grid } from '@mui/material'
import AvailabilitySwitch from '../Shared/AvailabilitySwitch'
import { TextBody2 } from '../Styles/TextCustom'
import { BaseButton } from '../Shared/BaseButton'
import {
  IUnassignUserFromParkingPoolData,
  IUnassignUserFromParkingPoolVars,
  UNASSIGN_USER_FROM_PARKING_POOL,
} from '../../graphql/parkingPool/unassignUserFromParkingPool'
import {
  IAssignUserToParkingPoolData,
  IAssignUserTParkingPoolVars,
  ASSIGN_USER_TO_PARKING_POOL,
} from '../../graphql/parkingPool/assignUserToParkingPool'
import {
  UPDATE_PARKING_POOL_USER_DATA_ENABLED,
  IUpdateParkingPoolUserDataEnabledData,
  IUpdateParkingPoolUserDataEnabledVars,
} from '../../graphql/parkingPool/updateParkingPoolUserEnabled'
import { ApolloError, useMutation } from '@apollo/client'
import { toast } from 'react-toastify'
import BaseModal from '../Shared/BaseModal'
import { ModalContent } from '../Styles/CustomElementsStyled'
import { themeColors } from '../../const/colors'
import Error from '../Shared/Error'

const AssignUserToParkingPool = ({
  refetch,
  listRefetch,
  assigned,
  login,
  id,
  parkingPoolDataId,
  disabled,
  activeSubscription,
}: {
  refetch: () => void
  listRefetch: () => void
  assigned: boolean
  login: string
  id: string
  parkingPoolDataId: string
  disabled: boolean
  activeSubscription: boolean
}) => {
  const { t } = useTranslation(['parking_pool'])
  const [errorMessage, setErrorMessage] = useState('')
  const [openModal, setOpenModal] = useState(false)
  const [toggleSwitch, setToggleSwitch] = useState(assigned)
  const successNotify = (message: string) => toast.dark(message)

  const onError = (err: ApolloError) => setErrorMessage(err.message)

  const onAssignCompleted = (data: IAssignUserToParkingPoolData) => {
    const { parkingPool } = data.updateParkingPool
    setOpenModal(false)
    if (!!parkingPool?.length) {
      setParkingPoolDataEnabled(true)
      refetch()
      listRefetch()
      successNotify(`${t('generic:success_message')}`)
    } else {
      setErrorMessage(t('assign_error'))
    }
  }

  const onUnassignCompleted = (data: IUnassignUserFromParkingPoolData) => {
    const { parkingPool } = data.updateParkingPool
    setOpenModal(false)
    if (!parkingPool?.length) {
      setParkingPoolDataEnabled(false)
      refetch()
      listRefetch()
      successNotify(`${t('generic:success_message')}`)
    } else {
      setErrorMessage(t('unassign_error'))
    }
  }

  const [assignUserToParkingPool] = useMutation<
    IAssignUserToParkingPoolData,
    IAssignUserTParkingPoolVars
  >(ASSIGN_USER_TO_PARKING_POOL, {
    onCompleted: onAssignCompleted,
    onError,
    variables: {
      id,
      login,
    },
  })
  const [unassignUserFromParkingPool] = useMutation<
    IUnassignUserFromParkingPoolData,
    IUnassignUserFromParkingPoolVars
  >(UNASSIGN_USER_FROM_PARKING_POOL, {
    onCompleted: onUnassignCompleted,
    onError,
    variables: {
      id,
      login,
    },
  })

  const setParkingPoolDataEnabled = async (enabled: boolean) => {
    await updateParkingPoolUser({
      variables: {
        id: parkingPoolDataId,
        enabled,
      },
    })
  }

  const [updateParkingPoolUser] = useMutation<
    IUpdateParkingPoolUserDataEnabledData,
    IUpdateParkingPoolUserDataEnabledVars
  >(UPDATE_PARKING_POOL_USER_DATA_ENABLED)

  const handleAvailabilitySwitch = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setToggleSwitch(event.target.checked)
  }

  const handleAssignUser = () => {
    setErrorMessage('')
    if (assigned === toggleSwitch) {
      return null
    }
    if (toggleSwitch) {
      assignUserToParkingPool()
    } else if (activeSubscription) {
      setOpenModal(true)
    } else {
      unassignUserFromParkingPool()
    }
  }

  return (
    <>
      <PaperSection>
        <Title>{t('assign_user')}</Title>
        <Container container alignItems={'flex-start'}>
          <Grid item xs={8}>
            <AvailabilitySwitch
              checked={toggleSwitch}
              checkedText={`${t('assigned_user')}`}
              uncheckedText={`${t('unassigned_user')}`}
              onChange={handleAvailabilitySwitch}
            />
          </Grid>
          <Grid item xs={4}>
            <BaseButton onClick={handleAssignUser} disabled={disabled}>
              {t('btn:save')}
            </BaseButton>
          </Grid>
        </Container>
        {disabled && (
          <CardInfo color={themeColors.gray}>
            {t('no_card_assigned_info')}
          </CardInfo>
        )}
        {errorMessage && <Error errorMessage={errorMessage} />}
      </PaperSection>
      <BaseModal
        confirmText={t('unassign_user')}
        open={openModal}
        handleClose={() => setOpenModal(false)}
        handleAccept={unassignUserFromParkingPool}
      >
        <ModalContent>{t('active_subscription_warning')}</ModalContent>
      </BaseModal>
    </>
  )
}

export default AssignUserToParkingPool

const Container = styled(Grid)`
  display: flex;
  justify-content: space-between;
  padding: 16px 0 0;
`
const PaperSection = styled.div`
  background-color: ${themeColors.white};
  border-radius: 16px;
  padding: 20px 16px;
  margin: 16px 0;
`
const CardInfo = styled(TextBody2)`
  margin-top: 16px;
  display: block;
`
