import gql from 'graphql-tag'
import { Roles } from '../../const/permissions'
import { IQueryBasicData } from '../IQueryData'

export interface ILoginVars {
  login: string
  password: string
  app?: string
  api_version: number
}

export interface ILoginData {
  login: IQueryBasicData & {
    accessToken: string
    refreshToken: string
    user: {
      roles: {
        name: Roles
      }[]
    }
  }
}

export const LOGIN_QUERY = gql`
  query login(
    $login: String!
    $password: String!
    $app: String!
    $api_version: Int!
  ) {
    login(
      login: $login
      password: $password
      service: "olivia"
      app: $app
      api_version: $api_version
    ) {
      accessToken
      code
      error
      message
      refreshToken
      status
      user {
        roles {
          name
        }
      }
    }
  }
`
