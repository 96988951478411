import { OrigoEventName } from '../graphql/hid/getOrigoAccount'
import { themeColors } from '../const/colors'
import i18n from 'i18next'

const deleted = [
  OrigoEventName.CREDENTIAL_REVOKED,
  OrigoEventName.ENDPOINT_TERMINATED,
  OrigoEventName.ENDPOINT_DELETE_INITIATED,
  OrigoEventName.CREDENTIAL_REVOKING,
]

const active = [
  OrigoEventName.CREDENTIAL_ISSUED,
  OrigoEventName.ENDPOINT_ACTIVE,
]

export const handleStatus = (
  status: OrigoEventName,
  accessGranted: boolean,
) => {
  if (active.includes(status) && accessGranted) {
    return i18n.t('hid_status:ACTIVE')
  } else return i18n.t(`hid_status:${status}`)
}

export const handleStatusDescription = (
  status: OrigoEventName,
  accessGranted: boolean,
) => {
  if (active.includes(status) && accessGranted) {
    return i18n.t('hid_status_description:ACTIVE')
  } else return i18n.t(`hid_status_description:${status}`)
}

export const getStatusColor = (status: string, accessGranted: boolean) => {
  if (active.includes(status as OrigoEventName) && accessGranted) {
    return themeColors.success
  } else if (deleted.includes(status as OrigoEventName)) {
    return themeColors.negative
  }
}
