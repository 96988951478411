import { useState, useEffect } from 'react'
import { useQuery } from '@apollo/client'
import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import {
  IHIDUser,
  IQueryUserVars,
  IQueryUserData,
  QUERY_USER_LIST,
} from '../../graphql/hid/queryUser'
import FullScreenLoader from '../../components/Shared/FullScreenLoader'
import { useAppSelector } from '../../redux/store'
import BaseDrawer from '../../components/Shared/BaseDrawer'
import HIDUsersTable from '../../components/HID/HIDUsersTable'
import { IUserDataRow } from '../../components/HID/HIDUsersTable'
import HIDUserDetails from '../../components/HID/HIDUserDetails'
import {
  GET_HID_CONFIG,
  IGetHIDConfigData,
  IGetHIDConfigVars,
} from '../../graphql/hid/getHIDConfig'
import { OrigoEventName } from '../../graphql/hid/getOrigoAccount'
import { themeColors } from '../../const/colors'
import HIDServiceData from '../../components/HID/HIDServiceData'
import HIDFilters from '../../components/HID/HIDFilters'

export const deletedStatuses = [
  OrigoEventName.USER_DELETED,
  OrigoEventName.USER_DELETE_INITIATED,
]

const HIDUsersList = () => {
  const { t } = useTranslation(['columns'])
  const { tenantName } = useAppSelector(state => state.user)
  const [openUserDetails, setOpenUserDetails] = useState<IUserDataRow>()
  const [hidAccountFilter, setHidAccountFilter] = useState('')
  const [activeCardFilter, setActiveCardFilter] = useState('')
  const [filteredUsers, setFilteredUsers] = useState<IHIDUser[]>([])

  const filterHidAccounts = (items: IHIDUser[], isAccount: boolean) => {
    const filtered = isAccount
      ? items.filter(({ origoAccounts }) => !!origoAccounts.length)
      : items.filter(({ origoAccounts }) => !origoAccounts.length)
    return filtered
  }

  const filterHidCards = (items: IHIDUser[], isActive: boolean) => {
    const filtered = isActive
      ? items.filter(
          ({ origoAccounts }) => !!origoAccounts[0]?.origoCards[0]?.deviceId,
        )
      : items.filter(
          ({ origoAccounts }) => !origoAccounts[0]?.origoCards[0]?.deviceId,
        )
    return filtered
  }

  const { data, loading, refetch } = useQuery<IQueryUserData, IQueryUserVars>(
    QUERY_USER_LIST,
    {
      fetchPolicy: 'no-cache',
      variables: {
        tenant: tenantName,
      },
    },
  )

  const { data: tenant } = useQuery<IGetHIDConfigData, IGetHIDConfigVars>(
    GET_HID_CONFIG,
    {
      fetchPolicy: 'no-cache',
      variables: {
        name: tenantName,
      },
    },
  )

  useEffect(() => {
    if (data) {
      let items = data.queryUser
      if (!!hidAccountFilter) {
        hidAccountFilter === 'yes'
          ? (items = filterHidAccounts(items, true))
          : (items = filterHidAccounts(items, false))
      }
      if (!!activeCardFilter) {
        activeCardFilter === 'yes'
          ? (items = filterHidCards(items, true))
          : (items = filterHidCards(items, false))
      }
      setFilteredUsers(items)
    }
  }, [data, hidAccountFilter, activeCardFilter])

  if (loading || !data) {
    return <FullScreenLoader />
  }

  if (!tenant?.getTenant.hidConfig) {
    return (
      <TypographyStyled variant="h6">
        {t('hid:no_hid_account_assigned')}
      </TypographyStyled>
    )
  }

  return (
    <>
      <Typography variant="h6" color={themeColors.primary}>
        {t('admin_menu:mobile_access')}
      </Typography>
      <TypographyStyled variant="subtitle1">
        {t('admin_menu:users_list')}
      </TypographyStyled>
      <HIDServiceData />
      <HIDUsersTable
        users={filteredUsers}
        setOpenUserDetails={setOpenUserDetails}
        refetch={refetch}
      >
        <HIDFilters
          hasAccount={hidAccountFilter}
          setHasAccount={setHidAccountFilter}
          activeCard={activeCardFilter}
          setActiveCard={setActiveCardFilter}
        />
      </HIDUsersTable>
      <BaseDrawer open={!!openUserDetails}>
        {!!openUserDetails && (
          <HIDUserDetails
            userData={openUserDetails}
            closeDrawer={() => setOpenUserDetails(undefined)}
            listRefetch={refetch}
            hidConfig={tenant?.getTenant?.hidConfig}
          />
        )}
      </BaseDrawer>
    </>
  )
}

const TypographyStyled = styled(Typography)`
  font-weight: 600;
  padding-bottom: 1rem;
`

export default HIDUsersList
