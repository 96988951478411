import { Navigate, useLocation } from 'react-router-dom'
import { useAppSelector } from '../redux/store'
import { checkRole } from '../utils/checkRole'
import { Roles } from '../const/permissions'
import { ROUTES } from '../const/routes'

const AuthWrapper = ({
  children,
  requiredRoles,
}: {
  children: JSX.Element
  requiredRoles: Roles[]
}) => {
  let location = useLocation()
  const { roles } = useAppSelector(state => state.user)
  if (!checkRole(roles, requiredRoles)) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to={ROUTES.LOGIN} state={{ from: location }} replace />
  }

  return children
}

export default AuthWrapper
