import React, { ReactNode, useRef } from 'react'
import styled from 'styled-components'
import { themeColors } from '../const/colors'
import { ToolbarPlaceholder } from './Styles/ToolbarPlaceholder'

const PageContentContainer = ({
  children,
  ...rest
}: {
  children: ReactNode
}) => {
  const placeholderRef = useRef<HTMLDivElement | any>()

  return (
    <Page>
      <ToolbarPlaceholder ref={placeholderRef} />
      <Content
        $toolbarHeight={placeholderRef.current?.clientHeight || 64}
        {...rest}
      >
        {children}
      </Content>
    </Page>
  )
}

const Page = styled.div`
  flex-grow: 1;
  background: ${themeColors.white};
  height: 100vh;
  overflow: hidden;
`
const Content = styled.main<{ $toolbarHeight: number }>`
  overflow-y: auto;
  ${({ $toolbarHeight }) => `height: calc(100vh - ${$toolbarHeight}px);`}
`

export default PageContentContainer
