import { Grid, Chip, Stack, Button } from '@mui/material'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { themeColors } from '../../const/colors'
import HIDFilter from '../../components/HID/HIDFilter'

const HIDFilters = ({
  hasAccount,
  setHasAccount,
  activeCard,
  setActiveCard,
}: {
  hasAccount: string
  setHasAccount: (v: string) => void
  activeCard: string
  setActiveCard: (v: string) => void
}) => {
  const { t } = useTranslation(['hid'])
  const handleClearFilters = () => {
    setHasAccount('')
    setActiveCard('')
  }
  return (
    <>
      <GridStyled container spacing={2}>
        <Grid item>
          <HIDFilter
            value={hasAccount}
            setValue={setHasAccount}
            label={t('card_created')}
          />
        </Grid>
        <Grid item>
          <HIDFilter
            value={activeCard}
            setValue={setActiveCard}
            label={t('mobile_card_activity')}
          />
        </Grid>
      </GridStyled>
      <Stack direction="row" spacing={1}>
        {hasAccount && (
          <ChipStyled
            label={`${t('card_created')}: ${t(`generic:${hasAccount}`)}`}
            onDelete={() => setHasAccount('')}
          />
        )}
        {activeCard && (
          <ChipStyled
            label={`${t('mobile_card_activity')}: ${t(
              `generic:${activeCard}`,
            )}`}
            onDelete={() => setActiveCard('')}
          />
        )}

        {(hasAccount || activeCard) && (
          <ButtonStyled variant="text" onClick={handleClearFilters}>
            {t('btn:clear_filters')}
          </ButtonStyled>
        )}
      </Stack>
    </>
  )
}

export default HIDFilters

const GridStyled = styled(Grid)`
  padding: 16px 0;
  flex-direction: row;
`
const ChipStyled = styled(Chip)`
  color: ${themeColors.primary};
  background-color: ${themeColors.lightBlue};
  font-size: 14px;
  font-weight: 500;
`
const ButtonStyled = styled(Button)`
  text-transform: none;
  font-weight: 700;
`
