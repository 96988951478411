import { useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import FullScreenLoader from '../../components/Shared/FullScreenLoader'
import { useAppSelector } from '../../redux/store'
import {
  GET_TENANT,
  IGetTenantData,
  IGetTenantVars,
} from '../../graphql/tenant/getTenant'
import { IParkingPoolSubscription } from '../../graphql/tenant/getTenant'
import ReservationTable from '../../components/Parking/ReservationTable'
import DateRangeFilter from '../../components/Parking/DateRangeFilter'

const Reservations = () => {
  const { tenantName } = useAppSelector(state => state.user)
  const [rangeFrom, setRangeFrom] = useState<Date | null>(null)
  const [rangeTo, setRangeTo] = useState<Date | null>(null)
  const [items, setItems] = useState<IParkingPoolSubscription[]>([])

  const { data, loading } = useQuery<IGetTenantData, IGetTenantVars>(
    GET_TENANT,
    {
      fetchPolicy: 'cache-and-network',
      variables: { name: tenantName },
    },
  )

  useEffect(() => {
    const subscriptions =
      data?.getTenant.parkingPool?.users
        .map(({ parkingSubscriptions }) => parkingSubscriptions)
        .flat() || []
    let array = subscriptions

    if (rangeFrom && !rangeTo && subscriptions?.length) {
      array = subscriptions.filter(
        ({ validTo }) => new Date(validTo) >= rangeFrom,
      )
    } else if (rangeFrom && rangeTo && subscriptions?.length) {
      array = subscriptions.filter(
        ({ validFrom, validTo }) =>
          new Date(validFrom) <= rangeTo && new Date(validTo) >= rangeFrom,
      )
    } else if (!rangeFrom && rangeTo && subscriptions?.length) {
      array = subscriptions.filter(
        ({ validFrom }) => new Date(validFrom) <= rangeTo,
      )
    }
    setItems(array)
  }, [rangeFrom, rangeTo, data])

  if (loading) {
    return <FullScreenLoader />
  }

  return (
    <ReservationTable
      items={items}
      showPlaces={!!data?.getTenant.parkingPool?.showPlaceNames}
    >
      <DateRangeFilter
        rangeFrom={rangeFrom}
        rangeTo={rangeTo}
        setRangeFrom={setRangeFrom}
        setRangeTo={setRangeTo}
      />
    </ReservationTable>
  )
}

export default Reservations
