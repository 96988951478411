import { NavMenuItem } from '../types/navigation'
import { faCarAlt, faIdCard } from '@fortawesome/free-solid-svg-icons'
import { ROUTES } from '../const/routes'
import { PARKING_POOL_ROLES, HID_ROLES } from './permissions'

export const navList: NavMenuItem[] = [
  {
    label: 'parking_areas',
    icon: faCarAlt,
    roles: PARKING_POOL_ROLES,
    to: '',
    content: [
      { label: 'data', to: ROUTES.PARKING_POOL_DATA },
      { label: 'users_list', to: ROUTES.PARKING_POOL_USERS },
      {
        label: 'reservations',
        to: ROUTES.PARKING_POOL_RESERVATIONS,
      },
      {
        label: 'occupancy_report',
        to: ROUTES.PARKING_POOL_OCCUPANCY_REPORT,
      },
    ],
  },
  {
    label: 'mobile_access',
    icon: faIdCard,
    roles: HID_ROLES,
    to: ROUTES.HID_USERS,
  },
]
