import Input from '../Shared/Input'
import { BaseButton } from '../Shared/BaseButton'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ApolloError, useMutation } from '@apollo/client'
import {
  ADD_PARKING_POOL_USER_DATA,
  IAddParkingPoolUserData,
  IAddParkingPoolUserDataVars,
} from '../../graphql/parkingPool/addParkingPoolUserData'
import { toast } from 'react-toastify'
import FullScreenLoader from '../Shared/FullScreenLoader'
import styled from 'styled-components'
import { TextBody2 } from '../Styles/TextCustom'
import { themeColors } from '../../const/colors'
import { CAR_PLATE_REGEX } from '../../const/regex'
import Error from '../Shared/Error'

type FormData = {
  enabled: boolean
  carPlate: string
  carPlate2: string
  accessCard: string
}

interface IProps {
  login: string
  accessCard?: string
  refetch: () => void
  listRefetch: () => void
}

const AddParkingPoolData = ({
  login,
  accessCard,
  refetch,
  listRefetch,
}: IProps) => {
  const [errorMessage, setErrorMessage] = useState('')
  const { t } = useTranslation(['user_details', 'tenant'])
  const successNotify = (message: string) => toast.dark(message)

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<FormData>()

  const addUserDataCompleted = async (data: IAddParkingPoolUserData) => {
    successNotify(`${t('generic:success_message')}`)
    refetch()
    listRefetch()
  }

  const [addParkingPoolUser, { loading }] = useMutation<
    IAddParkingPoolUserData,
    IAddParkingPoolUserDataVars
  >(ADD_PARKING_POOL_USER_DATA, {
    onCompleted: addUserDataCompleted,
    onError: (err: ApolloError) => setErrorMessage(err.message),
  })

  const updateSharedParking = async (data: FormData) => {
    await addParkingPoolUser({
      variables: {
        carPlate: data.carPlate.trim().toUpperCase(),
        carPlate2: data.carPlate2.trim().toUpperCase(),
        enabled: true,
        login,
      },
    })
  }

  if (loading) {
    return <FullScreenLoader />
  }

  return (
    <PaperSection>
      {!accessCard && (
        <CardInfo color={themeColors.gray}>
          {t('parking_pool:no_car_plate_assigned_info')}
        </CardInfo>
      )}
      <Input
        label={t('car_plate')}
        name={'carPlate'}
        control={control}
        errors={errors}
        rules={{
          required: `${t('form:field_required')}`,
          pattern: {
            value: CAR_PLATE_REGEX,
            message: t('form:valid_car_plate'),
          },
        }}
      />
      <Input
        label={t('car_plate')}
        name={'carPlate2'}
        control={control}
        errors={errors}
        rules={{
          pattern: {
            value: CAR_PLATE_REGEX,
            message: t('form:valid_car_plate'),
          },
        }}
      />
      <BaseButton
        disabled={!accessCard}
        onClick={handleSubmit(updateSharedParking)}
      >
        {t('btn:save')}
      </BaseButton>
      {errorMessage && <Error errorMessage={errorMessage} />}
    </PaperSection>
  )
}

export default AddParkingPoolData
const PaperSection = styled.div`
  background-color: ${themeColors.white};
  border-radius: 16px;
  padding: 20px 16px;
  margin: 16px 0;
`
const CardInfo = styled(TextBody2)`
  margin-bottom: 16px;
  display: block;
`
