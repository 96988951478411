import React, { useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { ApolloError, useMutation } from '@apollo/client'
import { themeColors } from '../../const/colors'
import { toast } from 'react-toastify'
import BaseModal from '../Shared/BaseModal'
import { BaseTextButton } from '../Shared/BaseButton'
import styled from 'styled-components'
import Error from '../Shared/Error'
import { Typography } from '@mui/material'
import { gql } from '@apollo/client'
import { IUserDataRow } from '../../components/HID/HIDUsersTable'
import { useForm } from 'react-hook-form'
import Input from '../Shared/Input'

type FormData = {
  comment: string
}

const DeleteSelectedHIDCards = ({
  items,
  refetch,
}: {
  items?: IUserDataRow[]
  refetch: () => void
}) => {
  const { t } = useTranslation(['hid'])
  const [openModal, setOpenModal] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const successNotify = (message: string) => toast.dark(message)

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<FormData>()
  const message = watch('comment')
  const generateDeleteMutation = useCallback(() => {
    const mutationFields = items
      ?.map(
        ({ login, deviceId }, index) => `
    DeleteMobileUserAccount${index}: DeleteMobileUserAccount(Login: "${login}", DeviceId: "${deviceId}", Comment: "${message}") {
      status
      error
      code
      message
    }
  `,
      )
      .join('\n')

    return gql`mutation DeleteCards{${mutationFields}}`
  }, [message, items])

  const [deleteCards, { loading }] = useMutation(generateDeleteMutation(), {
    onCompleted: () => {
      refetch()
      successNotify(t('card_deleted'))
    },
    onError: (error: ApolloError) => setErrorMessage(error.message),
  })

  const handleDelete = () => {
    setOpenModal(false)
    deleteCards()
      .then(response => {
        console.log('Response:', response)
        refetch()
      })
      .catch(err => {
        console.error('Error:', err)
        setErrorMessage(err)
        refetch()
      })
  }

  return (
    <>
      <ButtonStyled
        onClick={() => setOpenModal(true)}
        customColor={themeColors.negative}
        loading={loading}
        disabled={!items?.length}
      >
        {t('delete_HID_cards')}
      </ButtonStyled>
      {errorMessage && <Error errorMessage={errorMessage} />}
      <BaseModal
        confirmText={'btn:confirm'}
        open={openModal}
        handleClose={() => setOpenModal(false)}
        handleAccept={handleSubmit(handleDelete)}
      >
        <Wrapper>
          <TypographyStyled variant="subtitle1">
            {t('delete_selected_cards_confirmation')}
          </TypographyStyled>
          <Input
            label={t('deletion_selected_cards_comment')}
            name={'comment'}
            control={control}
            errors={errors}
            multiline
            rows={2}
          />
        </Wrapper>
      </BaseModal>
    </>
  )
}

export default DeleteSelectedHIDCards

const ButtonStyled = styled(BaseTextButton)`
  margin-top: 36px;
`
const Wrapper = styled.div`
  padding: 16px;
`
const TypographyStyled = styled(Typography)`
  padding-bottom: 16px;
`
