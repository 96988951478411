import React, { FC } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { TextButton } from '../Styles/TextCustom'
import { themeColors } from '../../const/colors'

const BaseModal: FC<{
  open: boolean
  handleClose?: (e: any) => void
  handleAccept?: () => void
  title?: string
  actions?: boolean
  confirmText?: string
  cancelText?: string
  handleClick?: (e: any) => void
  [k: string]: any
}> = ({
  open,
  handleClose,
  handleAccept,
  title,
  children,
  actions = true,
  confirmText = 'save',
  cancelText = 'cancel',
  handleClick,
}) => {
  const { t } = useTranslation('btn')
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      onClick={handleClick}
    >
      {title && <DialogTitle id="form-dialog-title">{title}</DialogTitle>}
      <CustomContent>{children}</CustomContent>
      {actions && (
        <CustomActions>
          <ButtonText
            onClick={handleClose}
            color={themeColors.primary}
            data-e2e="modal-cancel"
          >
            {t(`${cancelText}`)}
          </ButtonText>
          <ButtonText
            onClick={handleAccept}
            color={themeColors.primary}
            data-e2e="modal-confirm"
          >
            {t(`${confirmText}`)}
          </ButtonText>
        </CustomActions>
      )}
    </Dialog>
  )
}

export default BaseModal

const ButtonText = styled(TextButton)`
  padding: 0 8px;
`

const CustomActions = styled(DialogActions)`
  background-color: #fafafa;
  border: 1px solid rgba(0, 0, 0, 0.12);
  padding: 1px 16px;
`
const CustomContent = styled(DialogContent)`
  padding: 0;
`
