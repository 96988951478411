import { AccessCardType } from '../graphql/users/queryUsersList'

export const accessCardPlaceholder = (type?: AccessCardType): string => {
  switch (type) {
    case AccessCardType.prox40:
      return '1234567890'
    case AccessCardType.prox816:
      return '123.45678'
    case AccessCardType.seos:
      return '1234567'
    default:
      return ''
  }
}
