import {
  ParkingSubscriptionStatus,
  ReservationStatus,
} from '../graphql/tenant/getTenant'
import { themeColors } from '../const/colors'
import i18n from 'i18next'

export const getSubscriptionStatusColor = (
  status: ParkingSubscriptionStatus,
) => {
  switch (status) {
    case 'new':
      return 'initial'
    case 'pending':
      return themeColors.yellow
    case 'active':
      return themeColors.success
    case 'past':
      return themeColors.gray
    case 'cancelled':
      return themeColors.error
    case 'error':
      return themeColors.error
  }
}

export const getReservationStatusColor = (status: ReservationStatus) => {
  switch (status) {
    case 'rented':
      return themeColors.gray
    case 'reserved':
      return themeColors.gray
    case 'prereserved':
      return themeColors.gray
    case 'error':
      return themeColors.error
    case 'cancelled':
      return themeColors.error
  }
}

export const getBoolColor = (bool: boolean) =>
  bool ? themeColors.accept : themeColors.negative

export const getTextColor = (text: string) =>
  text === i18n.t(`bool:yes`) ? themeColors.accept : themeColors.negative
