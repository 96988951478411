import { Roles } from '../../const/permissions'
import { IQueryBasicData } from '../IQueryData'
import { gql } from '@apollo/client'

export interface IMyProfile {
  login: string
  firstName: string
  lastName: string
  tenant?: {
    name: string
  }
  language: string
  email: { email: string }
  workEmail: { email: string }
  roles: {
    name: Roles
  }[]
}

export interface IGetMyProfileData {
  getMyProfile: IQueryBasicData & {
    user: IMyProfile
  }
}

export const GET_MY_PROFILE_QUERY = gql`
  query getMyProfile {
    getMyProfile {
      status
      message
      code
      error
      user {
        login
        firstName: first_name
        lastName: last_name
        tenant {
          name
        }
        language
        email {
          email
        }
        workEmail: work_email {
          email
        }
        roles {
          name
        }
      }
    }
  }
`
