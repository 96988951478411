import { gql } from '@apollo/client'

export interface IUpdateParkingPoolUserDataData {
  updateParkingPoolUserData: {
    parkingPoolUserData: {
      carPlate: string
      carPlate2: string
    }
  }
}

export interface IUpdateParkingPoolUserDataVars {
  id: string
  carPlate: string
  carPlate2?: string
}

export const UPDATE_PARKING_POOL_USER_DATA = gql`
  mutation updateParkingPoolUserData(
    $id: [ID!]
    $carPlate: String!
    $carPlate2: String
  ) {
    updateParkingPoolUserData(
      input: {
        filter: { id: $id }
        set: { car_plate: $carPlate, car_plate_2: $carPlate2 }
      }
    ) {
      parkingPoolUserData(filter: { id: $id }) {
        id
        carPlate: car_plate
        carPlate2: car_plate_2
      }
    }
  }
`
